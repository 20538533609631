import React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";


function CustomizedDropdown(props) {
  const {
    options,
    handleChange,
    value,
    placeholderValue = "",
    name
  } = props;

  return (
    <FormControl fullWidth variant="standard">
      <Select
        name={name}
        value={value}
        onChange={handleChange}
        displayEmpty
        renderValue={
          !value
            ? () => (
              <span className="download-placeholder">
                {placeholderValue}
              </span>
            )
            : ""
        }
      >
       { name === 'condition' ?
        options?.map((option) => (
          <MenuItem
            value={option.condition_display_name}
            className="options"
          >
            {option.condition_display_name}
          </MenuItem>
        )) : 
        options?.map((option) => (
            <MenuItem
              value={option.value}
              className="options"
            >
              {option.value}
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  );
}

export default CustomizedDropdown;
