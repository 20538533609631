export const colorPalette = [
  '#A2B86C', '#EBC844', '#F16C20', '#C02E1D', '#0D3C55', '#0F5B78', '#1395BA', '#5CA793',
  '#FFE1A0', '#bd7ebe', '#E2F3FD', '#E6E1DD', '#48b5c4', '#ebdc78', '#ede15b', '#014c75', '#e8dcd2', '#62d8ff', '#448e73',
  '#FFBE15', '#FFD76F', '#E3F6FF', '#ff3657', '#F52126', '#0A62D8', '#70BBFD', '#CCDFE4', '#CFF0FF', '#FFEAB7',
  '#00798e', '#22a7f0', '#63bff0', '#a7d5ed', '#e2e2e2', '#e1a692', '#cbd6e4', '#bfcbdb', '#b3bfd1', '#a4a2a8',
  '#df8879', '#c86558', '#00ffff', '#76c8c8', '#98d1d1', '#badbdb', '#dedad2', '#304F6D', '#899481', '#E07D54',
  '#fd7f6f', '#7eb0d5', '#b2e061', '#ffb55a', '#ffee65', '#beb9db', '#fdcce5', '#8bd3c7', '#FC7C1A',
];

export const MonthInShortForm = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const AutomatedSegmentsKpiDescription = {
  BulkBuyers: [
    'The amount spent per transaction is very high.',
    'The item per transaction is very high.',
    'These retailers make a medium average number of orders',
    'The Retailer purchases frequency is almost 4 days.',
    'The bounce rate is high.',
    'On the basis above features, we can say these retailers are ordering Bulk orders.',
    'Since these retailers are bulk buyers.'
  ],

  ImpulsiveBuyers: [
    'These retailers make purchases very frequently and buy low-cost items.',
    'These retailers make a higher average number of orders.',
    'On the basis of ITEM_PER_ORDER and ORDER PER VALUE we can see that this retailer has mostly done small purchases with minimal items per Quantity.',
    'On a basic number of unique products, brands %, and upsell ratios we can see these groups of retailers are more loyalist towards brands.',
    'They are doing a very little search for brand and price comparisons on the app. They are ordering products almost at the same time as per Customers need',
  ],

  IndifferentBehaviourBuyers: [
    'These retailers make a low average number of orders.',
    'These retailers make low purchases and infrequent order transactions. ',
    'These groups of retailers getting very high scheme values almost  4.76 = 5% unique products getting schemes.',
    'These customers are benefit driven, they usually buy products when they get an offer.',
  ],

  RarePurchasers: [
    'These groups of retailers generate very low revenue.',
    'These retailers make a low average number of orders.',
    'Retailers in this group do purchase but rarely (very high latency).',
    'The amount of the purchases and the amount spent per transaction are very less.',
  ],

  SchemeseekerAndBenefitDriven: [
    'The amount of purchases and the amount spent per transaction is medium levels.',
    'The Retailer purchases frequency is almost 2.5  days. ',
    'These groups of retailers are medium-revenue retailers',
    'We can see this retailer does not have any significant behavior. (Indifferent Retailers)',
  ],

  Others: [
    'These retailers make purchases daily and buy low-cost items.',
    'These retailers make a high average number of orders.',
    'These groups of retailers have a high average revenue',
    'As we can see  this retailer has mostly done high-value purchases',
    'These are the Retailers which are the most complex type of consumer decision types. Retailers will research thoroughly before committing to investing',
  ],
}

export const AutomatedSegmentsKPI = {
  BulkBuyers: 'bulk buyers',
  ImpulsiveBuyers: 'impulsive buyers',
  IndifferentBehaviourBuyers: 'indifferent behaviour buyers',
  RarePurchasers: 'rare purchasers',
  SchemeseekerAndBenefitDriven: 'schemeseeker and benefit-driven',
  Others: 'others'
}

export const kpis = [
  {
    key: 'BulkBuyers',
    label: 'Bulk Buyers',
  },
  {
    key: 'ImpulsiveBuyers',
    label: 'Impulsive Buyers',
  },
  {
    key: 'IndifferentBehaviourBuyers',
    label: 'Indifferent Behaviour Buyers',
  },
  {
    key: 'RarePurchasers',
    label: 'Rare Purchasers',
  },
  {
    key: 'SchemeseekerAndBenefitDriven',
    label: 'Schemeseeker and Benefit Driven',
  },
  {
    key: 'Others',
    label: 'Others',
  },
]