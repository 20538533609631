import React, { useState, useEffect } from "react";
import { Tab, Tabs, Stack, Typography, Box, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import Pharmarack_logo from '../../Assets/Pharmarack_logo.png';
import './TabTable.css';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import API from "../config/API";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

export default function TabTable() {
  const [value, setValue] = React.useState(0);
  const [recentlyCreateddData, setRecentlyCreateddData] = useState({});
  const [mostDownloadedData, setMostDownloadedData] = useState([]);

  useEffect(() => {
    const recentlyCreatedRequestBody = {
      "page_number": 1,
      "page_limit": 5,
      "sort_by": "created_at",
      "reverse": true
    }
    const mostDownloadedRequestBody = {
      "page_number": 1,
      "page_limit": 5,
      "sort_by": "download_count",
      "reverse": true
    }
    API._GetRecentlyCreatedAndMostDownloadedData(recentlyCreatedRequestBody).then(({ data }) => {
      setRecentlyCreateddData(data);
    })
      .catch((error) => {
        console.error('Error:', error);
      });

    API._GetRecentlyCreatedAndMostDownloadedData(mostDownloadedRequestBody).then(({ data }) => {
      setMostDownloadedData(data);
      console.log(mostDownloadedData);
    })
      .catch((error) => {
        console.error('Error:', error);
      });

  }, []);

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  return (
    <Paper elevation={3}
      // style={{ minWidth: '44%', height: '24rem', margin: '1%', padding: '1rem', background: 'linear-gradient(to right, #FFFFFF,#94BFE5 )' }}>
      style={{ minWidth: '44%', height: '23.7rem', margin: '1%', padding: '1rem', background: '#FFFFFF', borderRadius: '0.625rem' }}>
      {/* <img src={Pharmarack_logo} alt="logo" style={{ position: 'absolute', marginLeft: '23%' }} /> */}
      <Tabs
        value={value}
        onChange={handleChange}
        // indicatorColor="secondary"
        textColor="primary"
        centered
        TabIndicatorProps={{
          style: { background: "white", borderBottom: '5px solid #FAAD2F', borderRadius: '15px', height: '0.8rem' }
        }}
        sx={{
          '& .css-1dz5wxq-MuiTabs-root:hover, & .css-1h9z7r5-MuiButtonBase-root-MuiTab-root:hover': {
            color: '#FAAD2F !important'
          },
        }}
      >
        {/* <Tab label="Most Used" /> */}
        <Tab style={{ textTransform: 'capitalize', fontSize: 'large' }} label="Recently Created" />
        <Tab style={{ textTransform: 'capitalize', fontSize: 'large' }} label="Most Downloaded" />
      </Tabs>
      <TabPanel value={value} index={0}>

        <Table stickyHeader size="small" aria-label="a audit list">
          <TableHead sx={{
            "& th": {
              color: "rgba(96, 96, 96)",
              backgroundColor: "#D1D1D1",
              position: 'sticky',
              top: '0',
              marginBottom: '1rem',
              fontWeight: 'bold',
            }
          }}
          >
            <TableRow>
              <TableCell align="left">Title</TableCell>
              <TableCell align="left">Description</TableCell>
              <TableCell align="center">Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {recentlyCreateddData?.data?.cohorts &&
              recentlyCreateddData?.data?.cohorts.map((row, rowIndex) => (
                <TableRow
                  key={rowIndex}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="left" style={{ maxWidth: '5.5rem' }}>{row.title}</TableCell>
                  <Tooltip title={row.description} placement="top">
                    <TableCell align="left" style={{ maxWidth: '7.5rem' }}>
                      {row.description?.length > 25 ? <>{row.description.substr(0, 26)}...</> : row.description}
                    </TableCell>
                  </Tooltip>
                  <TableCell align="center" style={{ maxWidth: '3.5rem' }}>{row.view_count}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>

      </TabPanel>
      <TabPanel value={value} index={1}>

        <Table stickyHeader size="small" aria-label="a audit list">
          <TableHead sx={{
            "& th": {
              color: "rgba(96, 96, 96)",
              backgroundColor: "#D1D1D1",
              position: 'sticky',
              top: '0',
              marginBottom: '1rem',
              fontWeight: 'bold',
            }
          }}
          >
            <TableRow>
              <TableCell align="left">Title</TableCell>
              <TableCell align="left">Description</TableCell>
              <TableCell align="center">Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mostDownloadedData?.data?.cohorts &&
              mostDownloadedData?.data?.cohorts.map((row, rowIndex) => (
                <TableRow
                  key={rowIndex}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="left" style={{ maxWidth: '5.5rem' }}>{row.title}</TableCell>
                  <Tooltip title={row.description} placement="top">
                    <TableCell align="left" style={{ maxWidth: '7.5rem' }}>
                      {row.description?.length > 25 ? <>{row.description.substr(0, 26)}...</> : row.description}
                    </TableCell>
                  </Tooltip>
                  <TableCell align="center" style={{ maxWidth: '3.5rem' }}>{row.download_count}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>

      </TabPanel>
    </Paper >
  );
}