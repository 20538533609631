import React from 'react'
import '../Dashboard.css';
import { Button, Grid, Radio, RadioGroup } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
const PincodeFilterData = {
    pincodes: [
        {
            pincode: 'All Pin codes',
            key: 1
        },
        {
            pincode: '700020- A.J.C.Bose Road',
            key: 2
        },
        {
            pincode: '711101- Belgachia ',
            key: 3
        },
        {
            pincode: '711316 - Brindabanpur',
            key: 4
        },
        {
            pincode: '711302 - Andul Mouri',
            key: 5
        },
        {
            pincode: '711303 - Agunshi',
            key: 6
        },
        {
            pincode: '711201 - BallyGaria Garden',
            key: 7
        },
        {
            pincode: '700084 -  Sales Tax Building',
            key: 8
        }
    ],
    cities: [
        {
            city: 'All Cities',
            key: 1
        },
        {
            city: 'Bhopal',
            key: 2
        },
        {
            city: 'Mumbai',
            key: 3
        },
        {
            city: 'Kolkata',
            key: 4
        },
        {
            city: 'Varanasi',
            key: 5
        },
        {
            city: 'Jaipur',
            key: 6
        },
        {
            city: 'Indore',
            key: 7
        },
        {
            city: 'Chennai',
            key: 8
        },
    ]
}

const PincodeFilter = (props) => {
    const { setIsPincodeFilterOpen, regionSelection, setRegionSelection } = props;
    const [pincodeFilter, setPincodeFilter] = React.useState(PincodeFilterData);
    const [regionSelectionTemp, setRegionSelectionTemp] = React.useState({
        region: ''
    });

    const handleCheckFilter = (event, itemIndex) => {
        const pincodeFilterCp = JSON.parse(JSON.stringify(pincodeFilter));
        if (pincodeFilterCp.cities[itemIndex]?.isChecked) {
            pincodeFilterCp.cities[itemIndex].isChecked = false;
        } else {
            pincodeFilterCp.cities[itemIndex].isChecked = true;
        }

        if (event.target.checked) {
            setRegionSelectionTemp([...regionSelectionTemp, event.target.value]);
        } else {
            setRegionSelectionTemp(regionSelectionTemp.filter((x) => x !== event.target.value));
        }

        setPincodeFilter(pincodeFilterCp);
    }

    const handleFinalSelect = (status = 'Apply') => {
        if (status === 'Apply') {
            setRegionSelection(regionSelectionTemp);
            setIsPincodeFilterOpen(null);
        } else {
            setRegionSelectionTemp({
                region: ''
            });
            setRegionSelection({
                region: ''
            });
        }
    }

    return (
        <div style={{ background: '#FFF', height: '70vh', width: '98%', paddingBottom: '1rem', paddingTop: '0.5rem', paddingLeft: '0.5rem' }}>
            <div className='brand-filter-heading'>
                <span style={{ marginLeft: '0.8rem' }}>Region</span>
                <span style={{ cursor: 'pointer' }} onClick={() => setIsPincodeFilterOpen(null)}><CloseIcon /></span>
            </div>
            <div className='brand-filter-sub-heading' style={{ marginLeft: '0.8rem' }}>Please Select Region</div>
            <Grid container spacing={2} style={{ paddingTop: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
                    <label className='brand-filter-labels'>Cities</label>
                    <div className='brand-sub-container'>

                        <TextField
                            id="input-with-icon-textfield"
                            label=""
                            name="brand"
                            placeholder="Search Region/City"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                '& .css-1ixds2g, & .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input, & .css-o9qtno-MuiFormControl-root-MuiTextField-root': {
                                    padding: '4.5px 4px',
                                    fontSize: '14px'
                                },
                                '& .css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root': {
                                    background: 'white'
                                },
                            }}
                        />
                        <RadioGroup
                            style={{ overflowY: 'auto', display: 'grid' }}
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={regionSelectionTemp.region}
                            onChange={(event) => setRegionSelectionTemp({
                                ...regionSelectionTemp,
                                'region': event.target.value
                            })}
                        >
                            {
                                pincodeFilter.cities.map((item, itemIndex) => (
                                    <FormControlLabel value={item.city} control={<Radio sx={{
                                        '& .MuiSvgIcon-root': {
                                            fontSize: 18,
                                        }
                                    }} />}
                                        label={item.city}
                                        sx={{
                                            '& .MuiTypography-root': {
                                                fontSize: '0.8rem'
                                            },
                                            marginLeft: '0',
                                        }} />
                                ))
                            }
                        </RadioGroup>
                    </div>
                </Grid>
            </Grid>

            <div className='brand-filter-btn-container'>
                <Button className='brand-filter-reset-btn' onClick={() => handleFinalSelect('Reset')}>Reset</Button>
                <Button className='brand-filter-apply-btn' onClick={() => handleFinalSelect('Apply')}>Apply</Button>
            </div>
        </div >
    )
}

export default PincodeFilter
