import React, { useState } from 'react';
import './QueryBuilder.css';
import { Card, Grid } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import ListSubheader from '@mui/material/ListSubheader';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import 'dayjs/locale/en-gb';
import CustomizedDropdown from '../CustomizedDropdown/CustomizedDropdown';
import SuggestionDropdown from '../suggestion-dropdown/SuggestionDropdown';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import API from '../config/API';

const QueryBuilder = (props) => {
    const { conditions, setConditions, setLoading, handleClick, isDisableRetailerCount, filterOptions, setFilterOptions } = props;
    const [valueOptionsArr, setValueOptionsArr] = useState([
        {
            type: 1,
            data: [],
            isDropdown: false,
        }
    ]);


    const getFilterOptions = () => {
        setLoading(true);
        API._GetFilters().then((response) => {
            setFilterOptions(response.data.data.filters);
            setLoading(false);
        })
            .catch((error) => {
                console.error('Error:', error);
                setLoading(false);
            })
    };

    const getValueOptions = async (filterId, search_str, itemIndex, filterType = 1, isDropdown = false) => {

        if (isDropdown) {
            API._GetValueOptions(filterId, search_str).then(({ data }) => {
                let response = {}
                if (data.status) {
                    response = {
                        type: filterType,
                        data: data.data.options,
                        isDropdown: isDropdown
                    }
                } else {
                    response = {
                        type: filterType,
                        data: [],
                        isDropdown: isDropdown
                    }
                }
                const valueOptionsArrCp = JSON.parse(JSON.stringify(valueOptionsArr));
                valueOptionsArrCp[itemIndex] = response;
                setValueOptionsArr(valueOptionsArrCp);
            })
                .catch((error) => {
                    console.error('Error:', error);
                })
        } else {
            const response = {
                type: filterType,
                data: [],
                isDropdown: isDropdown
            }
            const valueOptionsArrCp = JSON.parse(JSON.stringify(valueOptionsArr));
            valueOptionsArrCp[itemIndex] = response;
            setValueOptionsArr(valueOptionsArrCp);
        }
    };

    const getConditionOptions = async (filterId) => {

        let OperatorData = []
        setLoading(true);
        await API._GetConditionsOptions(filterId).then(({ data }) => {
            OperatorData = data.data.conditions
            setLoading(false);
        })
            .catch((error) => {
                console.error('Error:', error);
                setLoading(false);
            });
        return OperatorData;
    };

    const resetRuleValueOnFilterChange = (ruleObject) => {
        ruleObject.value = "";
        if (ruleObject.more.length > 0) {
            ruleObject.more[0].value = "";
        }
        return ruleObject;
    };

    const handleConditionsChange = async (event, itemIndex, subItemIndex = -1, isCheck = 'parent', newValue = '') => {
        const conditionsCp = JSON.parse(JSON.stringify(conditions));

        if (isCheck === 'toggle' && subItemIndex === -1) {
            conditionsCp[itemIndex]['conditionAndOr'] = event.target.value;
        }
        else if (isCheck === 'toggle' && subItemIndex !== -1) {
            conditionsCp[itemIndex].more[subItemIndex]['conditionAndOr'] = event.target.value;
        }
        else if (isCheck === 'parent') {
            if (event?.target?.name === "filterValue") {
                const object = filterOptions.find((x) => x.options.find((y) => y.filter_id === event.target.value))?.options.find((z) => z.filter_id === event.target.value)
                conditionsCp[itemIndex][event.target.name] = object.filter_id;
                const options = await getConditionOptions(object.filter_id);
                await getValueOptions(object.filter_id, '', itemIndex, object.data_type, object?.is_dropdown);
                conditionsCp[itemIndex]['operatorOptions'] = options;
                conditionsCp[itemIndex] = resetRuleValueOnFilterChange(conditionsCp[itemIndex]);
            }
            else if (event?.target?.name === "condition") {
                let conditionID = conditionsCp[itemIndex]['operatorOptions'].find((condition => findConditionID(condition, event)));
                // let conditionID = conditionsCp[itemIndex]['operatorOptions'].find((condition => condition.condition_display_name === event.target.value));
                conditionsCp[itemIndex][event.target.name] = conditionID;
            }
            else if (event?.target?.name === "value") {
                conditionsCp[itemIndex][event.target.name] = event.target.value;
            } else {

                if (conditionsCp[itemIndex].condition?.condition_display_name === 'IN') {
                    if (Array.isArray(newValue)) {
                        let res = '';
                        newValue.forEach((item) => {
                            if (item.key !== '')
                                res += item.key + ',';
                        });
                        res = res.slice(0, -1);
                        newValue = res;
                        conditionsCp[itemIndex]['value'] = newValue;
                    } else {
                        const object = filterOptions.find((x) => x.options.find((y) => y.filter_id === conditionsCp[itemIndex]?.filterValue))?.options.find((z) => z.filter_id === conditionsCp[itemIndex]?.filterValue)
                        getValueOptions(object?.filter_id, newValue, itemIndex, object.data_type, object?.is_dropdown);
                    }
                } else {
                    const object = filterOptions.find((x) => x.options.find((y) => y.filter_id === conditionsCp[itemIndex]?.filterValue))?.options.find((z) => z.filter_id === conditionsCp[itemIndex]?.filterValue)
                    conditionsCp[itemIndex]['value'] = newValue;
                    getValueOptions(object?.filter_id, newValue, itemIndex, object.data_type, object?.is_dropdown);
                }

            }


        } else {
            if (event?.target?.name === "condition") {
                let conditionID = conditionsCp[itemIndex]['operatorOptions'].find((condition => findConditionID(condition, event)));
                conditionsCp[itemIndex].more[subItemIndex][event.target.name] = conditionID;
            }
            else if (event?.target?.name === "value") {
                conditionsCp[itemIndex].more[subItemIndex]['value'] = event.target.value;
            } else {

                if (conditionsCp[itemIndex].more[subItemIndex].condition?.condition_display_name === 'IN') {
                    if (Array.isArray(newValue)) {
                        let res = '';
                        newValue.forEach((item) => {
                            if (item.key !== '')
                                res += item.key + ',';
                        });
                        res = res.slice(0, -1);
                        newValue = res;
                        conditionsCp[itemIndex].more[subItemIndex]['value'] = newValue;
                    } else {
                        const object = filterOptions.find((x) => x.options.find((y) => y.filter_id === conditionsCp[itemIndex]?.filterValue))?.options.find((z) => z.filter_id === conditionsCp[itemIndex]?.filterValue)
                        getValueOptions(object?.filter_id, newValue, itemIndex, object?.data_type, object?.is_dropdown);
                    }
                } else {
                    const object = filterOptions.find((x) => x.options.find((y) => y.filter_id === conditionsCp[itemIndex]?.filterValue))?.options.find((z) => z.filter_id === conditionsCp[itemIndex]?.filterValue)
                    conditionsCp[itemIndex].more[subItemIndex]['value'] = newValue;
                    getValueOptions(object?.filter_id, newValue, itemIndex, object?.data_type, object?.is_dropdown);
                }
            }
        }

        setConditions(conditionsCp);
    };

    const addCondition = (itemIndex) => {
        const conditionsCp = JSON.parse(JSON.stringify(conditions));
        if (conditionsCp[itemIndex].more.length === 0) {

            conditionsCp[itemIndex].more.push({
                condition: {},
                value: '',
                conditionAndOr: '1'
            });
            setConditions(conditionsCp);
        }
        // if (conditionsCp[itemIndex]?.more?.length >= 1) {
        //     setSubQueryVisible(false);
        // }
    };

    const handleDeleteCondition = (itemIndex, subItemIndex = -1) => {
        const conditionsCp = JSON.parse(JSON.stringify(conditions));
        const valueOptionsArrCp = JSON.parse(JSON.stringify(valueOptionsArr));

        if (subItemIndex === -1) {
            conditionsCp.splice(itemIndex, 1);
            valueOptionsArrCp.splice(itemIndex, 1);
            setValueOptionsArr(valueOptionsArrCp);
        } else {
            conditionsCp[itemIndex].more.splice(subItemIndex, 1);
        }
        setConditions(conditionsCp);

    };

    const handleAddRule = () => {
        const conditionsCp = JSON.parse(JSON.stringify(conditions));
        conditionsCp[conditions?.length - 1].conditionAndOr = '1';
        conditionsCp.push({
            filterValue: '',
            condition: '',
            value: '',
            conditionAndOr: '',
            more: []
        });

        const valueOptionsArrCp = JSON.parse(JSON.stringify(valueOptionsArr));
        valueOptionsArrCp[valueOptionsArr?.length] = { type: 1, data: [], isDropdown: false }
        setValueOptionsArr(valueOptionsArrCp);
        setConditions(conditionsCp);
        // setSubQueryVisible(true);
    };

    const handleDeleteRule = () => {
        const conditionsCp = JSON.parse(JSON.stringify(conditions));
        if (conditions?.length > 1) {
            let index = conditions?.length - 1;
            conditionsCp.splice(index, 1);
        }
        setConditions(conditionsCp);
    };

    const findConditionID = (condition, e) => {
        return condition.condition_display_name === e.target.value
    };

    React.useEffect(() => {
        getFilterOptions();
    }, []);


    return (
        <div>

            <div style={{ marginTop: '2rem', width: "69%", marginRight: "2rem", }}>

                {
                    conditions && conditions.map((item, itemIndex) => (
                        <>
                            <div className='containers'>
                                <Grid container style={{ padding: '0.3rem' }}>
                                    <Grid container spacing={2} xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: '10px 10px' }} className='queryBuilding-row-1'>
                                        <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} xl={1.5} style={{
                                            display: 'flex',
                                            gap: '2rem',
                                            fontWeight: '500',
                                        }}>
                                            Rule {itemIndex + 1}
                                        </Grid>
                                        <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.5} xl={0.5}>
                                            {conditions?.length > 1 &&
                                                <span>
                                                    <button style={{ cursor: 'pointer', backgroundColor: 'white', border: 'none' }}
                                                        onClick={() => handleDeleteCondition(itemIndex)}>
                                                        <DeleteOutlinedIcon fontSize="small" color="error" />
                                                    </button>
                                                </span>
                                            }
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={2} xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: '10px 10px' }} className='queryBuilding-row-1'>
                                        <Grid item xs={3.5} sm={3.5} md={3.5} lg={3.5} xl={3.5}>
                                            <div>
                                                <FormControl fullWidth variant="standard">
                                                    <InputLabel>Filter</InputLabel>
                                                    <Select value={item?.filterValue}
                                                        //value={filter} onChange={handleFilterChange}
                                                        onChange={(event) => handleConditionsChange(event, itemIndex, -1, 'parent')}
                                                        label="Filter" name="filterValue" >
                                                        <ListSubheader>Transactional</ListSubheader>
                                                        {filterOptions ? filterOptions[0].options.map((option) => (
                                                            <MenuItem key={option.filter_id} value={option.filter_id}>
                                                                {option.filter_display_name}
                                                            </MenuItem>
                                                        )) : null}

                                                        <ListSubheader>Product Attributes</ListSubheader>
                                                        {filterOptions ? filterOptions[1].options.map((option) => (
                                                            <MenuItem key={option.filter_id} value={option.filter_id}>
                                                                {option.filter_display_name}
                                                            </MenuItem>
                                                        )) : null}
                                                        <ListSubheader>Retailer Attributes</ListSubheader>
                                                        {filterOptions ? filterOptions[2].options.map((option) => (
                                                            <MenuItem key={option.filter_id} value={option.filter_id}>
                                                                {option.filter_display_name}
                                                            </MenuItem>
                                                        )) : null}
                                                        <ListSubheader>Distributor Attributes</ListSubheader>
                                                        {filterOptions ? filterOptions[3].options.map((option) => (
                                                            <MenuItem key={option.filter_id} value={option.filter_id}>
                                                                {option.filter_display_name}
                                                            </MenuItem>
                                                        )) : null}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </Grid>

                                        <Grid item xs={3.5} sm={3.5} md={3.5} lg={3.5} xl={3.5}>
                                            <div style={{ marginTop: '1rem' }}>
                                                <CustomizedDropdown options={item?.operatorOptions} value={item?.condition?.condition_display_name}
                                                    placeholderValue={'Select'}
                                                    name="condition"
                                                    handleChange={(event) => handleConditionsChange(event, itemIndex, -1, 'parent')} />
                                            </div>
                                        </Grid>

                                        <Grid item xs={3.5} sm={3.5} md={3.5} lg={3.5} xl={3.5}>
                                            {
                                                valueOptionsArr && valueOptionsArr[itemIndex] && valueOptionsArr[itemIndex].isDropdown ?
                                                    <div style={{ marginTop: '0.2rem' }}>
                                                        <SuggestionDropdown
                                                            label={"Value"}
                                                            name='value'
                                                            options={valueOptionsArr[itemIndex].data ? valueOptionsArr[itemIndex].data : []}
                                                            value={item?.value}
                                                            handleChange={(event, newValue) => handleConditionsChange(event, itemIndex, -1, 'parent', newValue)}
                                                            isMultiple={item?.condition?.condition_display_name === 'IN'}
                                                        />
                                                    </div>
                                                    :
                                                    valueOptionsArr && valueOptionsArr[itemIndex] && (valueOptionsArr[itemIndex].type === 5 || valueOptionsArr[itemIndex].type === 4) ?
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                            <DemoContainer components={['DatePicker']}>
                                                                <DatePicker
                                                                    onChange={(event) => handleConditionsChange(event, itemIndex, -1, 'parent', `${event.$y}-${event.$M + 1}-${event.$D}`)}
                                                                    sx={{
                                                                        '& .MuiInputBase-input-MuiOutlinedInput-input': {
                                                                            border: 'none'
                                                                        },
                                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                                            border: '0',
                                                                            borderBottom: '1px solid black',
                                                                            marginBottom: '1rem',
                                                                            borderRadius: '0',
                                                                        }
                                                                    }}
                                                                />
                                                            </DemoContainer>
                                                        </LocalizationProvider>
                                                        :
                                                        <div>
                                                            <TextField variant="standard" name="value" label="Value"
                                                                value={item?.value} onChange={(event) => handleConditionsChange(event, itemIndex, -1, 'parent')} fullWidth />
                                                        </div>
                                            }</Grid>

                                    </Grid>


                                    {item?.more && item.more.map((subItem, subItemIndex) => (
                                        <Grid container spacing={2} xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: '10px 10px' }} className='queryBuilding-row-1'>
                                            <Grid item xs={3.5} sm={3.5} md={3.5} lg={3.5} xl={3.5}>
                                                <div style={{ textAlign: 'center' }}>
                                                    <ToggleButtonGroup
                                                        value={subItem?.conditionAndOr}
                                                        exclusive
                                                        onChange={(event) => handleConditionsChange(event, itemIndex, subItemIndex, 'toggle')}
                                                        aria-label="Platform"
                                                        className='toggle-container'
                                                        sx={{
                                                            '& .Mui-selected': {
                                                                background: '#1439BB !important',
                                                                color: 'white !important',
                                                                lineHeight: '8px !important',
                                                            },
                                                            height: '2.2rem',
                                                            padding: '0.2rem'
                                                        }}
                                                    >
                                                        <ToggleButton value="1">And</ToggleButton>
                                                        <ToggleButton value="2">Or</ToggleButton>
                                                    </ToggleButtonGroup>
                                                </div>
                                            </Grid>

                                            <Grid item xs={3.5} sm={3.5} md={3.5} lg={3.5} xl={3.5}>
                                                <div style={{ marginTop: '1rem' }}>
                                                    <CustomizedDropdown options={item?.operatorOptions ? item?.operatorOptions : []}
                                                        value={subItem?.condition?.condition_display_name}
                                                        placeholderValue={'Select'}
                                                        name="condition"
                                                        handleChange={(event) => handleConditionsChange(event, itemIndex, subItemIndex, 'child')}
                                                    />
                                                </div>
                                            </Grid>

                                            <Grid item xs={3.5} sm={3.5} md={3.5} lg={3.5} xl={3.5}>
                                                {
                                                    valueOptionsArr && valueOptionsArr[itemIndex] && valueOptionsArr[itemIndex].isDropdown ?
                                                        <div>
                                                            <SuggestionDropdown
                                                                label={"Value"}
                                                                name='value'
                                                                options={valueOptionsArr[itemIndex].data ? valueOptionsArr[itemIndex].data : []}
                                                                value={subItem?.value}
                                                                handleChange={(event, newValue) => handleConditionsChange(event, itemIndex, subItemIndex, 'child', newValue)}
                                                                isMultiple={subItem?.condition?.condition_display_name === 'IN'}
                                                            />
                                                        </div>
                                                        :
                                                        valueOptionsArr && valueOptionsArr[itemIndex] && (valueOptionsArr[itemIndex].type === 5 || valueOptionsArr[itemIndex].type === 4) ?
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}  adapterLocale="en-gb">
                                                                <DemoContainer components={['DatePicker']}>
                                                                    <DatePicker
                                                                        onChange={(event) => handleConditionsChange(event, itemIndex, subItemIndex, 'child', `${event.$y}-${event.$M + 1}-${event.$D}`)}
                                                                        sx={{
                                                                            '& .MuiInputBase-input-MuiOutlinedInput-input': {
                                                                                border: 'none'
                                                                            },
                                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                                border: '0',
                                                                                borderBottom: '1px solid black',
                                                                                marginBottom: '1rem',
                                                                                borderRadius: '0',
                                                                            }
                                                                        }}
                                                                    />
                                                                </DemoContainer>
                                                            </LocalizationProvider>
                                                            :
                                                            <div>
                                                                <TextField variant="standard" label="Value" value={subItem?.value} name="value"
                                                                    onChange={(event) => handleConditionsChange(event, itemIndex, subItemIndex, 'child')}
                                                                    fullWidth />
                                                            </div>
                                                }

                                                {/* </div> */}
                                            </Grid>

                                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                                <button style={{ cursor: 'pointer', float: 'right', backgroundColor: 'white', border: 'none' }}
                                                    onClick={() => handleDeleteCondition(itemIndex, subItemIndex)}>
                                                    <DeleteOutlinedIcon fontSize="small" color="error" />
                                                </button>
                                            </Grid>

                                        </Grid>
                                    ))
                                    }

                                    {conditions[itemIndex]?.more?.length === 0 ?
                                        <Grid container spacing={2} xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: '10px 10px' }} className='queryBuilding-row-1'>
                                            <Grid item xs={3.5} sm={3.5} md={3.5} lg={3.5} xl={3.5} />
                                            <Grid item xs={3.5} sm={3.5} md={3.5} lg={3.5} xl={3.5}>
                                                <div className='plus-add-btn' onClick={() => addCondition(itemIndex)}>
                                                    <span>+</span>
                                                    <span>Add</span>
                                                </div>
                                            </Grid>
                                            <Grid item xs={3.5} sm={3.5} md={3.5} lg={3.5} xl={3.5} />
                                        </Grid> : null}
                                </Grid>
                            </div>

                            {conditions?.length > 1 && itemIndex !== conditions?.length - 1 &&
                                <div className='center-toggle-container'>
                                    <div className='center-toggle-div-1-3' />
                                    <div className='center-toggle-div-2'>
                                        <ToggleButtonGroup
                                            value={item?.conditionAndOr}
                                            exclusive
                                            onChange={(event) => handleConditionsChange(event, itemIndex, -1, 'toggle')}
                                            aria-label="Platform"
                                            className='toggle-container'
                                            sx={{
                                                '& .Mui-selected': {
                                                    background: '#1439BB !important',
                                                    color: 'white !important',
                                                    lineHeight: '8px !important',
                                                },
                                                height: '2.2rem',
                                                padding: '0.2rem'
                                            }}>
                                            <ToggleButton value="1">And</ToggleButton>
                                            <ToggleButton value="2">Or</ToggleButton>
                                        </ToggleButtonGroup>
                                    </div>
                                    <div className='center-toggle-div-1-3' />
                                </div>}
                        </>
                    ))

                }

            </div>

            <div className='add-rule-btn-div'>
                <button className='add-rule-btn' onClick={handleAddRule}>+ {" "}Add Rule</button>
                <button className='add-rule-btn'
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.5rem',
                        color: isDisableRetailerCount() && '#9ca79c',
                        cursor: isDisableRetailerCount() && 'not-allowed'
                    }}
                    onClick={handleClick}
                    disabled={isDisableRetailerCount()}
                >
                    Retailer Count
                    <RefreshRoundedIcon />
                </button>

                {conditions?.length > 1 ?
                    <button className='add-rule-btn' onClick={handleDeleteRule}>- {" "}Delete Rule</button> : null}
            </div>
        </div >
    )
};

export default QueryBuilder;
