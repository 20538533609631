import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import '../Dashboard.css';

const PincodeNormalTable = (props) => {
  const { pincodeData, pincodeDataHeaders } = props;
  return (
    <div
      style={{ height: '71vh', overflowY: 'scroll' }}
    >
      <Table stickyHeader size="small" aria-label="a audit list">
        <TableHead sx={{
          "& th": {
            color: "#00284B",
            backgroundColor: "white",
            position: 'sticky',
            top: '0',
            marginBottom: '1rem',
            fontSize: '12px',
            fontWeight: '500',
            borderBottom: 'none',
          },
        }}
        >
          <TableRow>
            {
              pincodeDataHeaders.map((item) => (
                <TableCell align="center">{item}</TableCell>
              ))
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {pincodeData &&
            pincodeData.map((row, rowIndex) => (
              <TableRow
                key={rowIndex}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  background: 'white',
                  '& .css-vtdehq-MuiTableCell-root, .css-dsuxgy-MuiTableCell-root': {
                    borderBottom: 'none'
                  }
                }}
              >
                <TableCell align="left" style={{ maxWidth: '5.5rem' }}>{row.pincode}</TableCell>
                <TableCell align="left" style={{ maxWidth: '5.5rem' }}>{row.area}</TableCell>
                <TableCell align="center" style={{ maxWidth: '3rem' }}>{row.mucaine}</TableCell>
                <TableCell align="center" style={{ maxWidth: '3rem' }}>{row.polycrol}</TableCell>
                <TableCell align="center" style={{ maxWidth: '3rem' }}>{row.panMpsO}</TableCell>
                <TableCell align="center" style={{ maxWidth: '3rem' }}>{row.gelucilMps}</TableCell>
                <TableCell align="center" style={{ maxWidth: '3rem' }}>{row.digene}</TableCell>
                <TableCell align="center" style={{ maxWidth: '3rem' }}>{row.acinilO}</TableCell>
                <TableCell align="center" style={{ maxWidth: '3rem' }}>{row.ranidomO}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default PincodeNormalTable
