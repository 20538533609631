import React, { useState, useRef, useEffect } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, Box, Grid } from '@mui/material';
import { useNavigate, useLocation } from "react-router-dom";
import QueryBuilder from '../../Components/QueryBuilder/QueryBuilder';
import { Card, Chip, TextField, Typography, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import Vectorimg from "../../Assets/Vectorimg.png";
import { CardContent } from '@material-ui/core';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Loader from '../../Components/loader/Loader';
import { toast } from 'react-toastify';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const REACT_APP_BACKEND_URL = 'http://mum-dev-rep-be-load-balancer-1924720443.ap-south-1.elb.amazonaws.com';
const EditCohortPage = () => {
const { state } = useLocation();
  const actionRef = useRef();
  const navigate = useNavigate();
  const [totalRetailorCount, setTotalRetailorCount] = useState(0);
  const retailer_Count = (totalRetailorCount) => {
    setTotalRetailorCount(totalRetailorCount);
  };
  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState('');
  const [cohortTitle, setCohortTitle] = useState('');
  const [cohortDescription, setCohortDescription] = useState('');
  const [queryCondition, setQueryCondition] = useState([]);
  const [ruleSet, setRuleSet] = useState([]);
  const [openCreateConfirmation, setOpenCreateConfirmation] = useState(false);
  const [viewResultPayload, setViewResultPayload] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [conditions, setConditions] = React.useState([
    {
      filterValue: '',
      condition: '',
      value: '',
      conditionAndOr: '',
      more: []
    }
  ]);
const [cohortData, SetCohortData] = React.useState();
  const error = cohortTitle === "";
  const handleTagInputChange = (event) => {
    setTagInput(event.target.value);
  };
  const handleAddTag = () => {
    if (tagInput.trim() !== '') {
      setTags([...tags, tagInput.trim()]);
      setTagInput('');
    }
  };
  const handleRemoveTag = (tagToRemove) => {
    setTags(tags.filter((tag) => tag !== tagToRemove));
  };
  // detail cohort js

  const createNewCohort = () => {
    setLoading(true);
    const createCohortAPIUrl = REACT_APP_BACKEND_URL + `/api/cohort/update/${state?.cohort_id}`;
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    };
    const requestBody = {
      "title": cohortTitle,
      "description": cohortDescription,
      "tags": tags,
      // "tags": ["Sun Pharma", "Retailors"],
      "rules": ruleSet
    }
    setViewResultPayload(requestBody);
    // Make a POST request with fetch
    fetch(createCohortAPIUrl, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json()) // Parse the response as JSON
      .then((data) => {
        console.log(data);
        setOpenCreateConfirmation(true);
        setCohortTitle('');
        setCohortDescription('');
        setTags([]);
        setRuleSet([]);
        setTimeout(() => {
          navigate('/');
        }, 3000);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error:', error);
        setLoading(false);
      });
  };

  // get retailor count function
  const getRetailerCount = (conditions) => {
    setLoading(true);
    const getRetailorCountAPIUrl = REACT_APP_BACKEND_URL + '/api/cohort/retailer-count';
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    };
    let copy_conditions = [];
    copy_conditions = JSON.parse(JSON.stringify(conditions));
    copy_conditions[0].conditionAndOr = null;
    for (let i = 0; i < copy_conditions.length; i++) {
      if ((i + 1) < copy_conditions.length) {
        copy_conditions[i + 1].conditionAndOr = conditions[i].conditionAndOr;
      }
    }

    // const rules = conditions.map((condition) => {
    const rules = copy_conditions.map((condition) => {
      const queryCondition = {
        filter_id: condition.filterValue,
        conditions: [
          {
            condition_id: condition.condition.condition_id,
            values: [condition.value],
            logic_id: condition.conditionAndOr ? parseInt(condition.conditionAndOr) : null,
            // logic_id: null,

          },
        ],
        // logic_id: 1
        // logic_id: condition.conditionAndOr ? parseInt(condition.conditionAndOr) : null,
        logic_id: condition.conditionAndOr ? parseInt(condition.conditionAndOr) : null,

      };

      // If there are sub-queries, add them
      if (condition.more.length > 0) {
        queryCondition.conditions.push({
          condition_id: condition.more[0].condition?.condition_id,
          values: [condition.more[0].value],
          // logic_id: condition.more[0].conditionAndOr ? parseInt(condition.more[0].conditionAndOr) : 1,
          logic_id: condition.more[0].conditionAndOr ? parseInt(condition.more[0].conditionAndOr) : null,

        });
      }

      return queryCondition;
    });

    const requestBody = {
      rules,
    };
    setRuleSet(rules);
    fetch(getRetailorCountAPIUrl, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json()) // Parse the response as JSON
      .then((data) => {
        console.log(data);
        // setRetailerCount(data.data.retailer_count);
        setTotalRetailorCount(data.data.retailer_count);
        // retailer_Count(data.data.retailer_count);
        setLoading(false);
        toast.success(data.message);
      })
      .catch((error) => {
        console.error('Error:', error);
        setLoading(false);
        toast.error("Something went wrong");
      });
  };
  // get reatilor count function
  const handleClick = () => {
    // const childData = actionRef.current.getData();
    const childData = conditions;
    console.log('childData...', childData);
    setQueryCondition(childData);
    getRetailerCount(childData);
  };
  const handleCreateClose = () => {
    setOpenCreateConfirmation(false);
  };

  const isDisableSaveAndResult = () => {
    if (!cohortTitle || !conditions[0].condition?.condition_display_name || !conditions[0].value || totalRetailorCount === 0) {
      return true;
    }

    return false;
  }

  useEffect(() => {
    // setLoading(true);
    const cardAPIUrl = REACT_APP_BACKEND_URL + `/api/cohort/fetch/${state.cohort_id}`;
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    };

    // Make a GET request with fetch
    fetch(cardAPIUrl, {
      method: 'GET',
      headers: headers,
    })
      .then((response) => response.json()) // Parse the response as JSON
      .then((data) => {
        
        SetCohortData(data.data);
        console.log(cohortData);
        data?.data?.title ? setCohortTitle(data.data.title) : setCohortTitle("");
        data?.data?.description ?  setCohortDescription(data.data.description) : setCohortDescription("");
        data?.data?.tags !== null ?  setTags(data.data.tags) : setTags([]);
        data?.data?.retailers_count  ? setTotalRetailorCount(data.data.retailers_count): setTotalRetailorCount(0);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);
  const isDisableRetailerCount = () => {
    if (!conditions[0].condition?.condition_display_name || !conditions[0].value) {
      return true;
    }

    return false;
  }
  
  return (
    <div style={{ backgroundColor: "#F3F6F9" }}>
      <Loader loading={loading} />
      <Box
        display={"flex"}
        marginTop={"10px"}
        marginBottom={"10px"}
        marginLeft="auto"
        alignItems={"center"}
        justifyContent={"space-between"}>
        <Button variant="text"
          sx={{ color: "black", fontSize: "1rem", textTransform: 'Capitalize', marginLeft: "2rem" }}
          onClick={() => { navigate('/home') }}
        ><ArrowBackIcon />Back</Button>
        <p style={{ fontSize: 'x-large' }}>Edit Cohort</p>
        <Button onClick={createNewCohort}
          disabled={isDisableSaveAndResult()}
          style={{ width: "7%", borderRadius: "0.5rem", marginRight: "1rem", backgroundColor: "#4591D5", textTransform: 'Capitalize' }} variant='contained'>Save</Button>
      </Box>

      
      <div style={{ display: "flex" }}>
        <Card style={{ width: "68%", marginRight: "2rem", marginLeft: "2rem", borderRadius: "1rem", padding: "1rem", boxShadow: 'none' }}>
          <Box >
            <Typography sx={{ color: "#1F71BA" }}>Cohort Details</Typography>

            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <TextField required fullWidth  helperText={error ? "Title is mandatory" : ""} error={error} label="Title" name="title" onChange={(event) => setCohortTitle(event.target.value)} value={cohortTitle} variant="standard" />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <TextField fullWidth label="Description" name="description" onChange={(event) => setCohortDescription(event.target.value)} value={cohortDescription} variant="standard" />
              </Grid>
            </Grid>

            <TextField
              style={{ marginTop: "2rem" }}
              fullWidth
              label="Tags"
              variant="standard"
              value={tagInput}
              onChange={handleTagInputChange}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  handleAddTag();
                }
              }}
            />
            <div>
              {tags.map((tag, index) => (
                <Chip
                  key={index}
                  label={tag}
                  deleteIcon={<ClearIcon style={{ color: "white" }} />}
                  onDelete={() => handleRemoveTag(tag)}
                  style={{
                    marginRight: '5px', marginBottom: '5px', backgroundColor: "#1F71BA",
                    border: "1px solid", borderRadius: "1px", color: "white"
                  }}
                />
              ))}
            </div>
          </Box>
        </Card>

        <Card style={{ width: "30%", borderRadius: '1rem', marginRight: "1rem", background: 'linear-gradient(to right top, #4591D5, #245E80 )' }}>
          <img src={Vectorimg} alt="logo" style={{ position: 'absolute', marginLeft: '21%' }} />
          <CardContent>
            <div >
              {/* <Typography variant='h3' style={{ textAlign: "center", color: "white" }}>{retailerCount}</Typography> */}
              <Typography variant='h3' style={{ textAlign: "center", color: "white" }}>{totalRetailorCount}</Typography>
              <Typography variant='h5' style={{ textAlign: "center", color: "white" }}>Total Count</Typography>
              <div style={{ textAlign: "center", marginTop: '8%' }}>
                {/* <div>
                  <IconButton style={{ color: 'white', textAlign: "center", fontSize: "1em" }} onClick={handleClick}>
                    Retailer Count
                    <RefreshRoundedIcon />
                  </IconButton>
                </div> */}

                {totalRetailorCount > 0 && cohortTitle ?
                  <IconButton style={{ color: 'white', textAlign: "center", fontSize: "1em" }} onClick={() => {
                    navigate('/viewresult', {
                      state: {
                        "title": cohortTitle, "description": cohortDescription,
                        "tags": tags, "rules": ruleSet, "retailer_count": totalRetailorCount, "cohort_saved": false, "cohort_id": null
                      }
                    })
                  }}
                    disabled={isDisableSaveAndResult()}
                  >
                    View Result
                    <ArrowForwardIcon />
                  </IconButton> : null}
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
      {/* Detail Cohort html */}
      <div>
      </div>

      <QueryBuilder conditions={conditions} setConditions={setConditions} setLoading={setLoading}
      handleClick={handleClick}
      isDisableRetailerCount={isDisableRetailerCount}
      ></QueryBuilder>
      {/* <QueryBuilder ref={rulesRef}  retailer_Count={retailer_Count}></QueryBuilder> */}
      <Snackbar open={openCreateConfirmation} autoHideDuration={6000} onClose={handleCreateClose}>
        <Alert onClose={handleCreateClose} severity="success" sx={{ width: '100%' }}>
          The cohort has been saved successfully !!
        </Alert>
      </Snackbar>
    </div>

  );
};

export default EditCohortPage;