import React from 'react'
import '../Dashboard.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';

const DateRangeFilter = (props) => {
    const { setIsDateRangeOpen, dateFilterSelection, setDateFilterSelection } = props;
    const disableFirstRender = React.useRef();
    const [dateRange, setDateRange] = React.useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    React.useEffect(() => {
        if (disableFirstRender.current) {
            const startDate = dateRange[0]?.startDate;
            const startDay = startDate.getDate().toString().padStart(2, '0');
            const startMonth = (startDate.getMonth() + 1).toString().padStart(2, '0');
            const startYear = startDate.getFullYear();

            const endDate = dateRange[0]?.endDate;
            const endDay = endDate.getDate().toString().padStart(2, '0');
            const endMonth = (endDate.getMonth() + 1).toString().padStart(2, '0');
            const endYear = endDate.getFullYear();

            setDateFilterSelection({
                dateRange: dateRange[0],
                isSelected: true,
                startDate: startDay+'/'+startMonth+'/'+startYear,
                endDate: endDay+'/'+endMonth+'/'+endYear
            })
        } else {
            disableFirstRender.current = true
        }
    }, [dateRange]);

    React.useEffect(() => {
        console.log('dateFilterSelection = ', dateFilterSelection);
    }, [dateFilterSelection]);

    return (
        <div style={{ background: '#FFF', height: '70vh', width: '98%', paddingBottom: '1rem', paddingTop: '0.5rem', paddingLeft: '0.5rem' }}>
            <DateRangePicker
                onChange={item => setDateRange([item.selection])}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={dateRange}
                direction="horizontal"
                preventSnapRefocus={true}
                calendarFocus="backwards"
            />
        </div >
    )
}

export default DateRangeFilter
