import axios from "axios";

const BASE_URL = process.env.REACT_APP_BACKEND_URL;

export const axiosNormal = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
});

export const axiosCancelToken = axios.CancelToken;