import React from 'react';
import ChartWrapper from '../ChartWrapper';
export default function BubbleChart(props) {
  const { google, chartData } = props;

  return (
    <div id='bubble-chart'>
      <ChartWrapper
        google={google}
        chartType="BubbleChart"

        options={{
          title: '',
          bar: { groupWidth: '50%' },
          tooltip: { isHtml: true },
          isStacked: 'percent',
          width: 900,
          height: 380,
          legend: 'none',
          colors: ['#4591D5'],
          vAxis: {
            textStyle: {
              color: '#15284B',
              fontSize: 12,
            },
            title: 'Market Share',
            minValue: 0,
            maxValue: 100,
            format: '#\'%\'',
          },
          hAxis: {
            title: 'Market Size',
          },
          bubble: {
            textStyle: {
              fontSize: 12,
              fontName: 'Times-Roman',
              color: 'green',
              bold: true,
              italic: true
            }
          },
          annotations: {
            alwaysOutside: true,
            textStyle: {
              color: '#616E84',
              fontSize: 12,
            },
          },
        }}
        chartData={chartData}
      />
    </div>
  );
}
