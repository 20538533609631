import React from 'react';
import './Dashboard.css';
import useGoogleCharts from '../../Components/charts/useGoogleChart';
import ColumnChart from '../../Components/charts/utils/ColumnChart';
import Grid from '@mui/material/Grid';
import { colorPalette } from '../../Components/contants/app_contants';
import ChartIcon from '../../Assets/dashboard/chart.svg';
import TropyIcon from '../../Assets/dashboard/trophy.svg';
import DonutChart from '../../Components/charts/utils/DonutChart';
import BarChart from '../../Components/charts/utils/BarChart';
import PincodeFilterTable from './components/PincodeFilterTable';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PincodeNormalTable from './components/PincodeNormalTable';
import BubbleChart from '../../Components/charts/utils/BubbleChart';
import BrandsFilter from './components/BrandsFilter';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip } from '@mui/material';
import PincodeFilter from './components/PincodeFilter';

import Popover from '@mui/material/Popover';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DateRangeFilter from './components/DateRangeFilter';
import LineChart from '../../Components/charts/utils/LineChart';
import RND from './components/RND';
import InfoIcon from '@mui/icons-material/Info';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const ChartOneData = [
    ['Genre', 'Fantasy & Sci Fi', 'Romance', 'Mystery/Crime', 'General',
        'Western', 'Literature', { role: 'annotation' }],
    ['Mar 23', 28, 19, 59, 31, 12, 13, ''],
    ['Apr 23', 28, 19, 89, 89, 12, 35, ''],
    ['May 23', 28, 19, 59, 30, 42, 13, ''],
    ['Jun 23', 28, 19, 29, 60, 22, 73, ''],
    ['Jul 23', 28, 19, 59, 34, 42, 93, ''],
    ['Aug 23', 28, 19, 19, 23, 12, 13, ''],
];

const ChartOneLeftData = [
    'MUCAINE', 'POLYCROL', 'PAN MPS O', 'PAN MPS', 'Geluail MPS', 'DIGENE',
    'ACINIL O', 'RANIDOM O', 'Others',
]

const cardsData = [
    {
        text: 'Mucaine has been the consistent overall leader. Followed by PAN MPS and Gelusil MPS',
        img: TropyIcon
    },
    {
        text: 'Performance of Ranidom O seems  consistent over the last 3 months',
        img: ChartIcon
    },
    {
        text: 'Mucaine has been the consistent overall leader. Followed by PAN MPS and Gelusil MPS',
        img: TropyIcon
    },
    {
        text: 'Performance of Ranidom O seems  consistent over the last 3 months',
        img: ChartIcon
    }
]

const donutChartData = [
    ['Task', 'Hours per Day'],
    ['Work', 11],
    ['Eat', 2],
    ['Commute', 2],
    ['Watch TV', 2],
    ['Sleep', 7]
]
const barChartData = [
    ['Element', 'Unit Sold', { role: 'style' }],
    ['10,300', 8.94, '#FB8E0D'],            // RGB value
    ['4,800', 10.49, '#FB8E0D'],            // English color name
    ['2,732', 19.30, '#FB8E0D'],
    ['1,673', 21.45, '#FB8E0D'], // CSS-style declaration
    ['1,232', 19.45, '#FB8E0D'], // CSS-style declaration
    ['982', 34, '#FB8E0D'], // CSS-style declaration
    ['432', 7, '#FB8E0D'], // CSS-style declaration
    ['170', 46, '#FB8E0D'], // CSS-style declaration
]

const pincodeData = [
    {
        pincode: '711112',
        area: 'Garia',
        mucaine: '2.34%',
        polycrol: '3.5%',
        panMpsO: '45.2%',
        gelucilMps: '5.6%',
        digene: '5.6%',
        acinilO: '5.7%',
        ranidomO: '12.45%'
    },
    {
        pincode: '711112',
        area: 'Garia',
        mucaine: '2.34%',
        polycrol: '3.5%',
        panMpsO: '45.2%',
        gelucilMps: '5.6%',
        digene: '5.6%',
        acinilO: '5.7%',
        ranidomO: '12.45%'
    },
    {
        pincode: '711112',
        area: 'Garia',
        mucaine: '2.34%',
        polycrol: '3.5%',
        panMpsO: '45.2%',
        gelucilMps: '5.6%',
        digene: '5.6%',
        acinilO: '5.7%',
        ranidomO: '12.45%'
    },
    {
        pincode: '711112',
        area: 'Garia',
        mucaine: '2.34%',
        polycrol: '3.5%',
        panMpsO: '45.2%',
        gelucilMps: '5.6%',
        digene: '5.6%',
        acinilO: '5.7%',
        ranidomO: '12.45%'
    },
    {
        pincode: '711112',
        area: 'Garia',
        mucaine: '2.34%',
        polycrol: '3.5%',
        panMpsO: '45.2%',
        gelucilMps: '5.6%',
        digene: '5.6%',
        acinilO: '5.7%',
        ranidomO: '12.45%'
    },
    {
        pincode: '711112',
        area: 'Garia',
        mucaine: '2.34%',
        polycrol: '3.5%',
        panMpsO: '45.2%',
        gelucilMps: '5.6%',
        digene: '5.6%',
        acinilO: '5.7%',
        ranidomO: '12.45%'
    },
    {
        pincode: '711112',
        area: 'Garia',
        mucaine: '2.34%',
        polycrol: '3.5%',
        panMpsO: '45.2%',
        gelucilMps: '5.6%',
        digene: '5.6%',
        acinilO: '5.7%',
        ranidomO: '12.45%'
    },
    {
        pincode: '711112',
        area: 'Garia',
        mucaine: '2.34%',
        polycrol: '3.5%',
        panMpsO: '45.2%',
        gelucilMps: '5.6%',
        digene: '5.6%',
        acinilO: '5.7%',
        ranidomO: '12.45%'
    },
    {
        pincode: '711112',
        area: 'Garia',
        mucaine: '2.34%',
        polycrol: '3.5%',
        panMpsO: '45.2%',
        gelucilMps: '5.6%',
        digene: '5.6%',
        acinilO: '5.7%',
        ranidomO: '12.45%'
    },
    {
        pincode: '711112',
        area: 'Garia',
        mucaine: '2.34%',
        polycrol: '3.5%',
        panMpsO: '45.2%',
        gelucilMps: '5.6%',
        digene: '5.6%',
        acinilO: '5.7%',
        ranidomO: '12.45%'
    },
    {
        pincode: '711112',
        area: 'Garia',
        mucaine: '2.34%',
        polycrol: '3.5%',
        panMpsO: '45.2%',
        gelucilMps: '5.6%',
        digene: '5.6%',
        acinilO: '5.7%',
        ranidomO: '12.45%'
    },
    {
        pincode: '711112',
        area: 'Garia',
        mucaine: '2.34%',
        polycrol: '3.5%',
        panMpsO: '45.2%',
        gelucilMps: '5.6%',
        digene: '5.6%',
        acinilO: '5.7%',
        ranidomO: '12.45%'
    },
]
const pincodeDataHeaders = ['PINCODE', 'AREA', 'MUCAINE', 'POLYCROL', 'PAN MPS O', 'GELUCIL MPS', 'DIGENE', 'ACINIL O', 'RAN DOM O'];

const barChartLabels = ['POLYCROL', 'PAN MPS O', 'PAN MPS', 'MUCAINE', 'Gelucil MPS', 'DIGENE', 'ACINIL O', 'RANIDOM O']

const bubbleChartData = [
    ['', 'Life Expectancy', 'Fertility Rate', 'Region', 'Population'],
    ['', 80.66, 61.67, 'North America', 33739900],
    ['', 79.84, 15.36, 'Europe', 81902307],
    ['', 78.6, 71.84, 'Europe', 5523095],
    ['', 72.73, 2.78, 'Middle East', 79716203],
    ['', 80.05, 32, 'Europe', 61801570],
    ['', 72.49, 11.7, 'Middle East', 73137148],
    ['', 68.09, 4.77, 'Middle East', 31090763],
    ['', 81.55, 22.96, 'Middle East', 7485600],
    ['', 68.6, 11.54, 'Europe', 141850000],
    ['', 78.09, 32.05, 'North America', 307007000]
]



const colors = ['#5570F1', '#FFCC91', '#E657BE', '#2FDCA3', '#FFF69E', '#FF7222', '#E3D5B1', '#F2BFF6', '#F8344C'];

const tableFilter = ['Pune', 'Mumbai', 'Banglore'];
// const tableFilter = [];

const Dashboard = () => {
    const google = useGoogleCharts();
    const [donutChartLabels, setDonutChartLabels] = React.useState([])
    const [tableTabValue, setTableTabValue] = React.useState(0)
    const [selectedTableFilter, setSelectedTableFilter] = React.useState(0)
    const [columnChartFilter, setColumnChartFilter] = React.useState('monthly')
    const [isFilterApplied, setIsFilterApplied] = React.useState(true)
    const [isBrandFilterOpen, setIsBrandFilterOpen] = React.useState(null)
    const [isPincodeFilterOpen, setIsPincodeFilterOpen] = React.useState(null)
    const [isDateRangeOpen, setIsDateRangeOpen] = React.useState(null);

    const [regionSelection, setRegionSelection] = React.useState({
        region: ''
    });
    const [brandFilterSelection, setBrandFilterSelection] = React.useState({
        brand: '',
        subgroup: '',
        competitorBrand: [],
    });
    const [dateFilterSelection, setDateFilterSelection] = React.useState({
        dateRange: {},
        isSelected: false,
        startDate: '',
        endDate: '',
    });

    const getDonutChartsLabels = () => {
        const donutChartDataCp = JSON.parse(JSON.stringify(donutChartData));
        donutChartDataCp.shift();

        const totalSum = donutChartDataCp.reduce((sum, item) => sum + item[1], 0);

        const percentages = donutChartDataCp.map((item, itemIndex) => {
            const percentage = (item[1] / totalSum) * 100;
            return [item[0], percentage.toFixed(2), colors[itemIndex]];
        });

        setDonutChartLabels(percentages);
    }

    React.useEffect(() => {
        getDonutChartsLabels();
    }, [])

    const pincodeFilterOpen = Boolean(isPincodeFilterOpen);
    const pincodeFilterId = pincodeFilterOpen ? 'simple-popover' : undefined;
    const brandFilterOpen = Boolean(isBrandFilterOpen);
    const brandFilterId = brandFilterOpen ? 'simple-popover' : undefined;
    const dateRangeOpen = Boolean(isDateRangeOpen);
    const dateRangeOpenId = dateRangeOpen ? 'simple-popover' : undefined;

    const tableTabs = (tabIndex) => (
        tabIndex === 1 ?
            <span className='table-tabs-info'>
                All Pin Codes
                <Tooltip title="All Pin Code Information" placement="top">
                    <InfoIcon style={{ fontSize: 20 }} />
                </Tooltip>
            </span>
            :
            tabIndex === 2 ?
                <span className='table-tabs-info'>
                    Top Pin Codes
                    <Tooltip title="Top Pin Codes Information" placement="top">
                        <InfoIcon style={{ fontSize: 20 }} />
                    </Tooltip>
                </span>
                :
                tabIndex === 3 ?
                    <span className='table-tabs-info'>
                        Focus Pin Codes
                        <Tooltip title="Focus Pin Codes Information" placement="top">
                            <InfoIcon style={{ fontSize: 20 }} />
                        </Tooltip>
                    </span>
                    :
                    tabIndex === 4 ?
                        <span className='table-tabs-info'>
                            Depooling Pin Codes
                            <Tooltip title="Depooling Pin Codes Information" placement="top">
                                <InfoIcon style={{ fontSize: 20 }} />
                            </Tooltip>
                        </span>
                        : ''
    )

    return (
        <div style={{ background: '#F3F6F9' }}>
            <button onClick={() => setIsFilterApplied(!isFilterApplied)}>Filter</button>

            {/* <RND /> */}


            <div className='filter-container'>
                <div>
                    <Button
                        onClick={(event) => setIsBrandFilterOpen(event.currentTarget)}
                        variant="outlined" endIcon={<KeyboardArrowDownIcon />}
                        sx={{
                            color: 'black',
                            background: '#FFF',
                            border: '1px solid #D9D9D9',
                        }}>
                        {brandFilterSelection.brand !== '' ? brandFilterSelection.brand : 'Brand'}
                    </Button>
                </div>
                <div>
                    <Button
                        onClick={(event) => setIsDateRangeOpen(event.currentTarget)}
                        variant="outlined" endIcon={<KeyboardArrowDownIcon />}
                        sx={{
                            color: 'black',
                            background: '#FFF',
                            border: '1px solid #D9D9D9',
                            width: '14rem'
                        }}>
                        {dateFilterSelection.startDate === '' && dateFilterSelection.endDate === '' ? 'Date Range' : dateFilterSelection.startDate + ' - ' + dateFilterSelection.endDate}
                    </Button>
                </div>
                <div>
                    <Button
                        onClick={(event) => setIsPincodeFilterOpen(event.currentTarget)}
                        variant="outlined" endIcon={<KeyboardArrowDownIcon />}
                        sx={{
                            color: 'black',
                            background: '#FFF',
                            border: '1px solid #D9D9D9',
                        }}>
                        {regionSelection?.length > 0 ? regionSelection[0] + '...' : 'Region'}
                    </Button>
                </div>
                <div>
                </div>

            </div>

            {/* <Grid container spacing={2} style={{ paddingTop: '1rem' }}>
                <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                    <div>
                        <div className='column-chart-left-container'>
                            {
                                ChartOneLeftData.map((item, index) => (
                                    <div>
                                        <span>
                                            <span style={{ background: colorPalette[index] }} className='color-indication'></span>
                                            <span className='color-indication-text'>{item}</span>
                                        </span>
                                        {
                                            index === 0 &&
                                            <div className='color-indication-heading'>Competitor Brands</div>
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
                    <div className='column-chart-container'>
                        <div className='column-chart-filter-container'>
                            <span
                                className={`${columnChartFilter === 'monthly' ? 'column-chart-filter-btn-selected' : 'column-chart-filter-btn-not-selected'}`}
                                onClick={() => setColumnChartFilter('monthly')}>Monthly</span>
                            <span
                                className={`${columnChartFilter === 'quarterly' ? 'column-chart-filter-btn-selected' : 'column-chart-filter-btn-not-selected'}`}
                                onClick={() => setColumnChartFilter('quarterly')}>Quarterly</span>
                        </div>
                        <LineChart google={google} title="Submission by Status" vAxisTitle="Market share (Units)" hAxisTitle="Months" chartData={ChartOneData} />
                    </div>
                </Grid>
            </Grid> */}

            <Grid container spacing={2} style={{ paddingTop: '1rem', width: '99%', margin: '0' }}>
                <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                    <div className='column-chart-container'>
                        <div className='column-chart-filter-container'>
                            <span
                                className={`${columnChartFilter === 'monthly' ? 'column-chart-filter-btn-selected' : 'column-chart-filter-btn-not-selected'}`}
                                onClick={() => setColumnChartFilter('monthly')}>Monthly</span>
                            <span
                                className={`${columnChartFilter === 'quarterly' ? 'column-chart-filter-btn-selected' : 'column-chart-filter-btn-not-selected'}`}
                                onClick={() => setColumnChartFilter('quarterly')}>Quarterly</span>
                        </div>
                        <div style={{ overflowY: 'hidden', overflowX: 'auto' }}>
                            <ColumnChart google={google} title="Submission by Status" vAxisTitle="Market share (Units)" hAxisTitle="Months" chartData={ChartOneData} />

                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                    <div className='pie-chart-container'>
                        <div className='dashboard-labels'>Brand Share</div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <DonutChart
                                google={google}
                                chartData={donutChartData}
                                pieHole="0.5"
                                sliceColors={colors}
                                chartArea={{
                                    top: 15, bottom: 15, width: window.innerWidth / 3,
                                }}
                                width={window.innerWidth / 3}
                                height='220'
                            />
                        </div>

                        <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                            <div class="table">
                                {
                                    donutChartLabels.map((item, itemIndex) => (
                                        itemIndex % 2 === 0 &&
                                        <div class="row">
                                            <React.Fragment>
                                                <div className='cell'> <div class="label-color-style" style={{ background: donutChartLabels[itemIndex][2] }}></div> </div>
                                                <div class="cell" style={{ paddingLeft: '1rem' }}>{donutChartLabels[itemIndex][0]}</div>
                                                <div class="cell" style={{ paddingLeft: '2rem', paddingRight: '3rem', fontWeight: '500' }}>{donutChartLabels[itemIndex][1]}%</div>
                                            </React.Fragment>
                                            {donutChartLabels[itemIndex + 1] &&
                                                <React.Fragment>
                                                    <div className='cell'> <div class="label-color-style" style={{ background: donutChartLabels[itemIndex + 1][2] }}></div> </div>
                                                    <div class="cell" style={{ paddingLeft: '1rem' }}>{donutChartLabels[itemIndex + 1][0]}</div>
                                                    <div class="cell" style={{ paddingLeft: '2rem', fontWeight: '500' }}>{donutChartLabels[itemIndex + 1][1]}%</div>
                                                </React.Fragment>
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                        </Grid>
                    </div>
                </Grid>
            </Grid>


            <div className='card-main-container'>
                {
                    cardsData.map((card) => (
                        <div className='card-container'>
                            <span><img src={card.img} alt='' className='card-img' /> </span>
                            <span className='card-text'>{card.text}</span>
                        </div>
                    ))
                }
            </div>


            {/* <Grid container spacing={2} style={{ marginTop: '1rem', overflow: 'hidden', paddingLeft: '1rem', paddingRight: '1rem' }}>

                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <div className='bar-chart-container'>
                        <div className='dashboard-labels' style={{ marginLeft: '5rem' }}>Unit Sold</div>
                        <div className='bar-chart-inner-container'>
                            <div className='bar-chart-labels'>
                                {
                                    barChartLabels.map((item, itemIndex) => (
                                        <div style={{ display: 'flex', gap: '2rem', marginLeft: '1rem' }}>
                                            <div>{itemIndex + 1}</div>
                                            <div style={{ width: '6rem' }}>{item}</div>
                                        </div>
                                    ))
                                }
                            </div>
                            <div>
                                <BarChart google={google} chartData={barChartData} />
                            </div>
                        </div>
                    </div>
                </Grid>



                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <div className='level-2-chart-container'>
                        <div className='dashboard-labels'>Brand Share</div>
                        <div>
                            <DonutChart
                                google={google}
                                chartData={donutChartData}
                                pieHole="0.5"
                                sliceColors={colors}
                                chartArea={{
                                    top: 15, bottom: 15, width: window.innerWidth / 3,
                                }}
                                width={window.innerWidth / 3}
                                height='220'
                            />
                        </div>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div class="table">
                                {
                                    donutChartLabels.map((item, itemIndex) => (
                                        itemIndex % 2 === 0 &&
                                        <div class="row">
                                            <React.Fragment>
                                                <div className='cell'> <div class="label-color-style" style={{ background: donutChartLabels[itemIndex][2] }}></div> </div>
                                                <div class="cell" style={{ paddingLeft: '1rem' }}>{donutChartLabels[itemIndex][0]}</div>
                                                <div class="cell" style={{ paddingLeft: '2rem', paddingRight: '3rem', fontWeight: '500' }}>{donutChartLabels[itemIndex][1]}%</div>
                                            </React.Fragment>
                                            {donutChartLabels[itemIndex + 1] &&
                                                <React.Fragment>
                                                    <div className='cell'> <div class="label-color-style" style={{ background: donutChartLabels[itemIndex + 1][2] }}></div> </div>
                                                    <div class="cell" style={{ paddingLeft: '1rem' }}>{donutChartLabels[itemIndex + 1][0]}</div>
                                                    <div class="cell" style={{ paddingLeft: '2rem', fontWeight: '500' }}>{donutChartLabels[itemIndex + 1][1]}%</div>
                                                </React.Fragment>
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                        </Grid>
                    </div>
                </Grid>


            </Grid> */}


            {isFilterApplied ?
                <Grid container spacing={2} style={{ marginTop: '1rem', marginBottom: '1rem', overflow: 'hidden', paddingLeft: '2rem', paddingRight: '1rem' }}>

                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3} style={{ height: '92vh', background: 'rgb(229, 247, 254)', marginTop: '1rem' }}>
                        <div>
                            <div className='table-left-container'>
                                {
                                    tableFilter.map((item, index) => (
                                        <div
                                            className={`${selectedTableFilter === index ? 'table-left-selected-text' : 'table-left-not-selected-text'}`}
                                            onClick={() => setSelectedTableFilter(index)}
                                        >{item}</div>
                                    ))
                                }
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={9} lg={9} xl={9} style={{ height: '92vh', marginTop: '1rem', filter: 'drop-shadow(-10px 4px 20px rgba(0, 0, 0, 0.10))' }} className='region-right-container'>
                        <Box sx={{ width: '100%' }} className="region-right-container">
                            <div className='dashboard-labels' style={{ padding: '1.5rem 0 0 1.5rem' }}>{tableFilter[selectedTableFilter]}</div>
                            <div className='dashboard-labels' style={{
                                padding: '0.5rem 0px 1rem 1.5rem',
                                fontSize: '0.9rem'
                            }}>119 - Pincodes</div>
                            <Box sx={{ width: '100%' }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={tableTabValue} onChange={(event, newValue) => setTableTabValue(newValue)} aria-label="basic tabs example"
                                        sx={{
                                            '& .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected, & .MuiTabs-root ': {
                                                color: 'white',
                                                borderRadius: '0.375rem 0.375rem 0rem 0rem',
                                                background: '#00284B',
                                                fontSize: '0.7rem',
                                                fontWeight: '500',
                                            },
                                        }}
                                        TabIndicatorProps={{
                                            style: { display: 'none' }
                                        }}
                                    >
                                        <Tab label={tableTabs(1)} {...a11yProps(0)} />
                                        <Tab label={tableTabs(2)} {...a11yProps(1)} />
                                        <Tab label={tableTabs(3)} {...a11yProps(2)} />
                                        <Tab label={tableTabs(4)} {...a11yProps(3)} />
                                    </Tabs>
                                </Box>
                                <CustomTabPanel value={tableTabValue} index={0}>
                                    <PincodeFilterTable pincodeData={pincodeData} pincodeDataHeaders={pincodeDataHeaders} />
                                </CustomTabPanel>
                                <CustomTabPanel value={tableTabValue} index={1}>
                                    <PincodeFilterTable pincodeData={pincodeData} pincodeDataHeaders={pincodeDataHeaders} />
                                </CustomTabPanel>
                                <CustomTabPanel value={tableTabValue} index={2}>
                                    <PincodeFilterTable pincodeData={pincodeData} pincodeDataHeaders={pincodeDataHeaders} />
                                </CustomTabPanel>
                                <CustomTabPanel value={tableTabValue} index={3}>
                                    <div className='depooling-chart-container'>
                                        <BubbleChart google={google} chartData={bubbleChartData} />
                                    </div>
                                </CustomTabPanel>
                            </Box>
                        </Box>
                    </Grid>

                </Grid>

                :
                <Grid container spacing={2} style={{ marginTop: '1rem', overflow: 'hidden', paddingLeft: '1rem', paddingRight: '1rem' }}>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ height: '80vh' }}>
                        <div style={{ background: 'white', borderRadius: '0.8rem' }}>
                            <span className='dashboard-labels' style={{ marginLeft: '5rem' }}>Top Pin Codes</span>
                            <PincodeNormalTable pincodeData={pincodeData} pincodeDataHeaders={pincodeDataHeaders} />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ height: '80vh' }}>
                        <div style={{ background: 'white', borderRadius: '0.8rem' }}>
                            <span className='dashboard-labels' style={{ marginLeft: '5rem' }}>Focus Pin Codes</span>
                            <PincodeNormalTable pincodeData={pincodeData} pincodeDataHeaders={pincodeDataHeaders} />
                        </div>
                    </Grid>
                </Grid>
            }

            <Popover
                id={pincodeFilterId}
                open={pincodeFilterOpen}
                anchorEl={isPincodeFilterOpen}
                onClose={() => setIsPincodeFilterOpen(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <PincodeFilter setIsPincodeFilterOpen={setIsPincodeFilterOpen}
                    regionSelection={regionSelection} setRegionSelection={setRegionSelection}
                />
            </Popover>

            <Popover
                id={dateRangeOpenId}
                open={dateRangeOpen}
                anchorEl={isDateRangeOpen}
                onClose={() => setIsDateRangeOpen(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <DateRangeFilter setIsDateRangeOpen={setIsDateRangeOpen}
                    dateFilterSelection={dateFilterSelection}
                    setDateFilterSelection={setDateFilterSelection} />
            </Popover>

            <Popover
                id={brandFilterId}
                open={brandFilterOpen}
                anchorEl={isBrandFilterOpen}
                onClose={() => setIsBrandFilterOpen(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <BrandsFilter setIsBrandFilterOpen={setIsBrandFilterOpen}
                    brandFilterSelection={brandFilterSelection}
                    setBrandFilterSelection={setBrandFilterSelection} />
            </Popover>


        </div>
    )
}

export default Dashboard
