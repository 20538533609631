import { Card, TextField, Button } from "@mui/material";
import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import CollectionsBookmarkOutlinedIcon from "@mui/icons-material/CollectionsBookmarkOutlined";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { makeStyles } from '@material-ui/core/styles';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-gb';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useNavigate } from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import Loader from '../../Components/loader/Loader';
import { toast } from 'react-toastify';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import API from "../config/API";

// const pagesize = 10;
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles((theme) => ({
  roundButton: {
    borderRadius: '30%', // Makes the button round
    width: '40px', // Adjust the width and height as needed
    height: '40px',
    border: '1px',
    backgroundColor: 'white'
  },
}));

// const REACT_APP_BACKEND_URL = 'http://mum-dev-rep-be-load-balancer-1924720443.ap-south-1.elb.amazonaws.com';
const { REACT_APP_BACKEND_URL } = process.env;
const DisplayTable = ({ loading, setLoading }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [cohortdata, setcohortdata] = useState([]);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [searchParameter, setSearchParameter] = React.useState('');
  const [toDate, setToDate] = React.useState(null);
  const [fromDate, setFromDate] = React.useState(null);
  const [hasData, setHasData] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDownload, setOpenDownload] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [rowToDelete, setRowToDelete] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [pageNumber, setPageNumber] = React.useState(0);


  const getTableData = (searchText, from_Date, to_Date) => {
    setLoading(true);
    let searchByTitle = searchText !== '' ? searchText : "";
    let toDate = (to_Date !== null || to_Date !== undefined || to_Date !== '' || to_Date !== 0) ? new Date(to_Date).getTime() : 0;
    let fromDate = (from_Date !== null || from_Date !== undefined || from_Date !== '' || from_Date !== 0) ? new Date(from_Date).getTime() : 0;
    const requestBody = {
      "title": searchByTitle,
      "from_date": fromDate,
      "to_date": toDate,
      "page_number": pageNumber + 1,
      "page_limit": rowsPerPage,
      "sort_by": "created_at",
      "reverse": true
    }
    API._GetCohortListData(requestBody).then((response) => {
      for (let i = 0; i < response.data?.data?.cohorts.length; i++) {
        response.data.data.cohorts[i].created_at = formatDate(response.data.data.cohorts[i].created_at);
      }
      setcohortdata(response.data);
      response.data?.data?.cohorts.length ? setHasData(true) : setHasData(false);
      setLoading(false); // Update the rowCount with the total number of rows
    })
      .catch((error) => {
        console.error('Error:', error);
        setLoading(false);
      });
  }

  React.useEffect(() => {
    getTableData();
  }, [pageNumber, rowsPerPage])

  React.useEffect(() => {
    console.log("COHO = ", cohortdata?.data?.cohorts.slice(pageNumber * rowsPerPage, (pageNumber + 1) * rowsPerPage));
  }, [cohortdata])


  // console.log("cohort",{cohortdata});
  const handleDeleteClick = (idToDelete) => {
    // Set the ID of the row to be deleted
    setRowToDelete(idToDelete);
    // Open the dialog for confirmation
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteClose = () => {
    // Close the dialog
    setIsDeleteDialogOpen(false);

    // Clear the ID of the row to be deleted
    setRowToDelete(null);
  };
  const handleDeleteConfirmed = (cohort_id) => {

    API._DeleteCohortListData(cohort_id).then((response) => {
      if (response.status === 200) {
        // Remove the deleted row from cohortdata
        const updatedCohortData = cohortdata.data.cohorts.filter((row) => row.id !== cohort_id);
        // Update the state with the filtered data
        setcohortdata({ data: { cohorts: updatedCohortData } });
        setAlertOpen(true); // deletion confirmation alert
        getTableData("", 0, 0); // calling function to get table data from servr after a cohort is deleted
      } else {
        console.error('Error deleting row');
      }
    })
      .catch((error) => {
        console.error('Error deleting row:', error);
      });


    // Close the dialog
    setIsDeleteDialogOpen(false);

    // Clear the ID of the row to be deleted
    setRowToDelete(null);
  };

  const getCohortDetails = (cohort_id) => {
    setLoading(true);

    API._GetCohortDetail(cohort_id).then((response) => {
      if (response.data.data) {
        setLoading(false);
        navigate('/viewresult', {
          state: {
            "title": response?.data.data?.title ? response?.data.data?.title : "", "description": response?.data.data.description ? response?.data.data.description : "",
            "tags": response?.data.data?.tags ? response?.data.data?.tags : [], "rules": response?.data.data?.rules ? response?.data.data.rules : [], "retailer_count": response?.data?.data?.retailers_count ? response?.data.data.retailers_count : 0,
            "cohort_saved": true, "cohort_id": cohort_id
          }
        });
      }
    })
      .catch((error) => {
        console.error('Error:', error);
        setLoading(false);
      });
  };

  const handleClose = () => {
    // Close the dialog
    setOpen(false);
  };

  const editCohortDetails = (cohort_id) => {
    navigate('/editcohort', {
      state: {
        "cohort_id": cohort_id
      }
    });
  }

  const handleDownloadClick = (fileName, cohort_id) => {
    setLoading(true);

    API._DownloadCohortDetail(cohort_id).then((response) => {
      const blob = new Blob([response.data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${cohort_id}_${fileName}.csv`);

      document.body.appendChild(link);
      link.click();

      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);

      toast.success("File downloaded successfully");
      setLoading(false);
    })
      .catch((error) => {
        console.error('Error downloading file:', error);
        setLoading(false);
        toast.error("Something went wrong");
      }); API._DownloadCohortDetail(cohort_id).then((response) => {
        const blob = new Blob([response.data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${cohort_id}_${fileName}.csv`);

        document.body.appendChild(link);
        link.click();

        // Clean up
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);

        toast.success("File downloaded successfully");
        setLoading(false);
      })
        .catch((error) => {
          console.error('Error downloading file:', error);
          setLoading(false);
          toast.error("Something went wrong");
        });
  };

  function formatDate(inputDate) {
    let date = new Date(inputDate);
    let day = date.getDate().toString().padStart(2, '0');
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let year = date.getFullYear();
    let hours = date.getHours().toString().padStart(2, '0');
    let minutes = date.getMinutes().toString().padStart(2, '0');
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours %= 12;
    hours = hours ? hours : 12;

    let formattedDate = `${day}/${month}/${year} ${hours}:${minutes} ${ampm}`;
    return formattedDate;
  }

  const tableColumns = ['Segment ID', 'Segment Title', 'Description', 'Create Date', 'Retailers Count']

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false);
  }
  const handleDownloadClose = () => {
    setOpenDownload(false);
  }

  const searchChangeHandler = (e) => {
    if (e.key === 'Enter' || e.type === 'click') {
      getTableData(searchParameter, fromDate, toDate);
    }
  }

  const resetFilters = () => {
    setFromDate(null);
    setToDate(null);
    setSearchParameter('');
    getTableData('', 0, 0);
  }

  React.useEffect(() => {
    if (fromDate && toDate) {
      getTableData(searchParameter, fromDate, toDate);
    }
  }, [fromDate, toDate]);

  return (
    <Card sx={{ marginTop: "0.5rem", borderRadius: '0.625rem' }} >
      <div style={{ display: "flex", margin: '1%' }}>
        <div style={{
          borderRadius: "0.5rem", marginLeft: "1rem", width: "11rem", height: "2.5rem",
          backgroundColor: "#CAD1D8", color: "#1F71BA", textAlign: "center",
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <CollectionsBookmarkOutlinedIcon style={{ marginRight: '0.5rem' }} />
          All Segment-<b>{cohortdata.data?.total_count}</b>
        </div>

        <div className="searchBox" style={{ marginLeft: "auto" }}>
          <TextField
            fullWidth
            size="small"
            value={searchParameter}
            // onChange={(e) => searchChangeHandler(e)}
            onChange={(e) => setSearchParameter(e.target.value)}
            onKeyPress={searchChangeHandler}
            placeholder="Search by Segment Title"
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <SearchRoundedIcon onClick={searchChangeHandler} sx={{ color: "#1F71BA" }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{
              '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline, & .css-igs3ac': {
                border: 'none',
                borderBottom: '2px solid gray'
              }
            }}
          />

        </div>

        {/* <Button onClick={resetFilters}><FilterListIcon /></Button> */}
        <div style={{ marginLeft: '1%' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb" >
            <DatePicker
              value={fromDate}
              onChange={(newValue) => setFromDate(newValue)}
              slotProps={{ textField: { size: 'small' } }}
              label="From"
              sx={{
                width: "168px", height: "4px",
                '& .MuiOutlinedInput-notchedOutline, & .css-igs3ac': {
                  border: 'none',
                  borderBottom: '2px solid gray'
                }
              }}
            />
            <DatePicker
              value={toDate}
              onChange={(newValue) => setToDate(newValue)}
              slotProps={{ textField: { size: 'small' } }}
              label="To"
              sx={{
                width: "168px", height: "4px", marginLeft: '1rem',
                '& .MuiOutlinedInput-notchedOutline, & .css-igs3ac': {
                  border: 'none',
                  borderBottom: '2px solid gray'
                }
              }}
            />
          </LocalizationProvider>
        </div>
        {searchParameter || fromDate || toDate ?
          <Button onClick={resetFilters}><ClearRoundedIcon /></Button> : null}
      </div>

      <div style={{ width: '100%' }}>
        {hasData ? (
          <>
            {/* <div style={{ height: 395, width: '100%' }}> */}
            {/* <DataGrid
              sx={{ '& .super-app-theme--header': { backgroundColor: '#CAD1D8' }, }}
              rows={cohortdata?.data?.cohorts ? cohortdata.data.cohorts : [{ id: '' }]}
              columns={columns}
              disableColumnMenu={true}
              disableDensitySelector={true}
              checkboxSelection={false}
              getRowId={(rows) => rows?.id}
              paginationMode="server"
              rowCount={rowCountState}
              pageSizeOptions={[5, 10, 20]}
              paginationModel={paginationModel}
              onPaginationModelChange={handlePaginationModelChange}
            /> */}

            <Paper sx={{ width: '100%' }}>
              <TableContainer component={Paper}
                style={{ height: '52vh', overflowY: 'scroll' }}
              >
                <Table stickyHeader size="small" aria-label="a audit list">
                  <TableHead sx={{
                    "& th": {
                      color: "rgba(96, 96, 96)",
                      backgroundColor: "#D1D1D1",
                      position: 'sticky',
                      top: '0',
                      marginBottom: '1rem',
                      fontWeight: 'bold',
                    }
                  }}
                  >
                    <TableRow>
                      {
                        tableColumns.map((item) => (
                          // <TableCell align={item === 'Retailers Count' ? "center" : "left"}>{item}</TableCell>
                          <TableCell align={"left"}>{item}</TableCell>
                        ))
                      }
                      <TableCell align="left"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {cohortdata?.data?.cohorts &&
                      cohortdata.data.cohorts.slice(0, rowsPerPage).map((row, rowIndex) => (
                        <TableRow
                          key={rowIndex}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <Tooltip title={row.id} placement="top">
                            <TableCell component="th" scope="row" style={{ width: '15%', maxWidth: '15%' }}>
                              {row.id?.length > 15 ? <>{row.id.substr(0, 16)}...</> : row.id}
                            </TableCell>
                          </Tooltip>
                          <TableCell align="left" style={{ width: '10%', maxWidth: '10%' }}>{row.title}</TableCell>
                          <Tooltip title={row.description} placement="top">
                            <TableCell component="th" scope="row" style={{ width: '17%', maxWidth: '17%' }}>
                              {row.description?.length > 30 ? <>{row.description.substr(0, 30)}...</> : row.description}
                            </TableCell>
                          </Tooltip>
                          <TableCell align="left" style={{ width: '15%', maxWidth: '15%' }}>{row.created_at}</TableCell>

                          <TableCell align="left" style={{ width: '10%', maxWidth: '10%' }}>{row.retailers_count}</TableCell>

                          <TableCell align="left" style={{ width: '15%', maxWidth: '15%', padding: row.isEdit && '0', height: !row.isEdit && '2rem' }}>
                            <>
                              <button className={classes.roundButton} style={{ marginRight: '6px', cursor: 'pointer' }}
                                onClick={() => getCohortDetails(row.id)}
                                disabled={selectedRow === row.id} >
                                <span className="table-operation-icons">
                                  <VisibilityIcon fontSize="small" />
                                </span>
                              </button>
                              <button className={classes.roundButton} style={{ marginRight: '6px', cursor: 'pointer' }}
                                onClick={() => editCohortDetails(row.id)}>
                                <span className="table-operation-icons">
                                  <EditOutlinedIcon fontSize="small" color="warning" />
                                </span>
                              </button>
                              <button className={classes.roundButton} style={{ marginRight: '6px', cursor: 'pointer' }}
                                onClick={() => handleDeleteClick(row.id)}
                              >
                                <span className="table-operation-icons">
                                  <DeleteOutlinedIcon fontSize="small" color="error" />
                                </span>
                              </button>
                              <button style={{ cursor: 'pointer' }} className={classes.roundButton} onClick={() => handleDownloadClick(row.title, row.id)}
                                disabled={selectedRow === row.id}>
                                <span className="table-operation-icons">
                                  <FileDownloadIcon fontSize="small" color="success" />
                                </span>
                              </button>

                            </>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>

              </TableContainer>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={cohortdata?.data?.total_count}
                rowsPerPage={rowsPerPage}
                page={pageNumber}
                onPageChange={(event, newPage) => setPageNumber(newPage)}
                onRowsPerPageChange={(event) => { setRowsPerPage(parseInt(event.target.value, 10)); setPageNumber(0); }}
              />
            </Paper>

            {/* </div> */}
          </>
        ) :
          (<span style={{ marginTop: '0.5rem', marginLeft: '44%', display: 'block', height: '3rem' }}><em><strong>No Records to Display</strong></em></span>)
        }

        {/* Dialog to display row data */}
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Segment Details</DialogTitle>
          <DialogContent>
            {selectedRow && (
              <div>
                <p>ID: {selectedRow.id}</p>
                <p>Created At: {selectedRow.created_at}</p>
                <p>Title: {selectedRow.title}</p>
                <p>Description: {selectedRow.description}</p>
                <p>Retailer Count: {selectedRow.retailers_count}</p>
              </div>
            )}
            <Button onClick={handleClose} variant="outlined" color="primary">
              Close
            </Button>
          </DialogContent>
        </Dialog>
        {/* Delete Confirmation Dialog */}
        <Dialog open={isDeleteDialogOpen} onClose={handleClose}>
          <DialogTitle>Delete Confirmation</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this segment?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteClose} color="primary">
              Cancel
            </Button>
            <Button onClick={() => handleDeleteConfirmed(rowToDelete)} color="primary">
              Delete
            </Button>

          </DialogActions>
        </Dialog>

        <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleAlertClose}>
          <Alert onClose={handleAlertClose} severity="success" sx={{ width: '100%' }}>
            The segment has been deleted successfully !!
          </Alert>
        </Snackbar>

        <Snackbar open={openDownload} autoHideDuration={6000} onClose={handleDownloadClose}>
          <Alert onClose={handleDownloadClose} severity="success" sx={{ width: '100%' }}>
            The segment has been downloaded successfully !!
          </Alert>
        </Snackbar>

      </div>
    </Card >
  );
};

export default DisplayTable;
