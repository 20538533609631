export const formatNumber = (num, decimalCount = 2) => {
    // Check if the number is a floating-point number
    if (num % 1 !== 0) {
        // If it is, round it to 2 decimal places
        num = num.toFixed(decimalCount);
    }
    num = num.toString();
    // Check if the number has a decimal part (after rounding)
    if (num && num?.includes('.') && num?.endsWith('00')) {
        // If the decimal part is '.00', remove it
        num = num.slice(0, -3);
    }

    return Number(num);
}

export const convertLongNumberIntoSmallWithUnit = (number) => {
    const num = Number(number);
    if (num >= 1000000) {
        const newNum = `${formatNumber((num / 1000000), 1)}M`;
        return newNum;
    } else if (num >= 1000) {
        const newNum = `${formatNumber((num / 1000), 1)}K`;
        return newNum;
    } else {
        return formatNumber(num, 2);
    }
}