import React, { useState } from "react";
import {
  Card,
  CardContent,
  Divider,
  Typography,
  Box,
  Button,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import LocationCityIcon from '@mui/icons-material/LocationCity';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert'
import Loader from '../../Components/loader/Loader';
import { toast } from 'react-toastify';
import API from "../config/API";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { convertLongNumberIntoSmallWithUnit } from "../config/helper";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const useStyles = makeStyles((theme) => ({
  roundBox: {
    color: 'white',
    padding: '0.3rem',
    marginRight: '1rem',
    borderRadius: '0.2rem',
    backgroundColor: '#FB8E0D',
    fontSize: '0.9rem',
  },
  rulesStyle: {
    background: 'rgb(243, 246, 249)',
    borderRadius: '0.4rem',
    marginTop: '1rem',
    padding: '0.6rem 0.5rem 0.6rem 0.5rem',
    color: 'black',
    fontSize: '0.9rem',
    fontWeight: '500',
    lineHeight: '1.7rem',
  },
}));

const ViewResultCard = ({ cohortDetails }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [openDownload, setOpenDownload] = useState(false);
  const [kpis, setKpis] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleDownloadClose = () => {
    setOpenDownload(false);
  }
  const createNewCohort = () => {
    setLoading(true);

    const requestBody = {
      "title": cohortDetails.title,
      "description": cohortDetails.description,
      "tags": cohortDetails?.tags ? cohortDetails?.tags : [],
      "rules": cohortDetails.rules
    }
    API._CreateCohort(requestBody).then(({ data }) => {

      API._DownloadCohortDetail(data.data.cohort_id).then((response) => {
        const blob = new Blob([response.data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${data.data.cohort_id}_${cohortDetails.title}.csv`);

        document.body.appendChild(link);
        link.click();

        // Clean up
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);

        toast.success("File downloaded successfully");
        setLoading(false);
      }).catch((error) => {
        console.error('Error downloading file:', error);
        setLoading(false);
        toast.error("Something went wrong");
      })
    }).catch((error) => {
      console.error('Error downloading file:', error);
    });;
  };

  const handleGetSegmentKPIs = () => {

    const requestBody = {
      rules: cohortDetails.rules
    }
    API._GetSegmentKpis(requestBody).then(({ data }) => {
      setKpis(data.data.segment_kpis);
    }).catch((error) => {
      console.error('Error getting segment file:', error);
    });;
  };

  const downloadCohort = () => {
    setLoading(true);

    API._DownloadCohortDetail(cohortDetails.cohort_id).then((response) => {
      const blob = new Blob([response.data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${cohortDetails.cohort_id}_${cohortDetails.title}.csv`);

      document.body.appendChild(link);
      link.click();

      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);

      toast.success("File downloaded successfully");
      setLoading(false);
      setOpenDownload(true);
    })
      .catch((error) => {
        console.error('Error downloading file:', error);
        setLoading(false);
      });
  };

  const getConditionOperator = (operator) => {
    operator = operator?.toLowerCase();
    if (operator === "greater than") return '>';
    else if (operator === "less than") return '<';
    else if (operator === "equals to") return '==';
    else if (operator === "not equals to") return '!=';
    else if (operator === "in") return 'IN';
    else if (operator === "greater than or equal to") return '>=';
    else if (operator === "less than or equal to") return '<=';
    else if (operator === "group by filter") return 'Group By Filter';
    else if (operator === "like") return 'Like';
    else return 'operator';
  }

  const getFilterLabel = (filterId) => {
    let label = ''
    cohortDetails.filters.forEach((group) => {
      if (group.options.find((filter) => filter.filter_id === filterId) !== undefined) {
        label = group.options.find((filter) => filter.filter_id === filterId)?.filter_display_name
      }
    });
    return label;
  }

  React.useEffect(() => {
    handleGetSegmentKPIs();
  }, []);


  return (
    <div>
      <Loader loading={loading} />

      <Box style={{ display: 'flex', gap: '1rem', justifyContent: 'right', margin: '0 0.4rem 0.5rem 0' }}>

        <Button className="btn-style" onClick={() => { navigate('/') }} sx={{ backgroundColor: '#4591D5', marginRight: '1rem' }} variant="contained" size="small" style={{ textTransform: 'Capitalize' }} >
          <ArrowBackIcon />Back
        </Button>

        {cohortDetails.cohort_saved === false ?
          <Button className="btn-style" onClick={createNewCohort} sx={{ backgroundColor: '#4591D5' }} variant="contained" size="small" style={{ textTransform: 'Capitalize' }} >
            <ArrowDownwardIcon />Save & Download
          </Button>
          : cohortDetails.cohort_saved === true ?
            <Button className="btn-style" onClick={downloadCohort} variant="contained" size="small" style={{ textTransform: 'Capitalize' }} >
              <ArrowDownwardIcon />Download
            </Button>
            : null
        }

      </Box>

      <div style={{ display: "flex" }}>
        {/* Left Half */}
        <div style={{ flex: 1, width: '50%', overflowWrap: 'break-word' }}>
          <Card style={{ borderRadius: '1rem 0rem 0rem 0rem', boxShadow: 'none', height: "100%" }}>
            <CardContent >
              <Typography style={{ fontSize: "20px" }}>
                {cohortDetails?.title}
              </Typography>
              <Typography style={{ marginBottom: "20px", fontSize: "15px" }}>
                {cohortDetails?.description}
              </Typography>
              {cohortDetails?.tags.length > 0 ? cohortDetails?.tags.map((obj) => (
                <Box component="span" className={classes.roundBox}>
                  {obj}
                </Box>
              )) : null}

              <div className={classes.rulesStyle} style={{ maxHeight: '75px',  overflowY: 'auto' }}>
                {
                  cohortDetails.cohort_saved ?
                    cohortDetails.rules?.length > 0 && cohortDetails.rules.map((item) => (
                      item.conditions.map((subItem) => (
                        <span> <span style={{ color: '#FB8E0D', margin: subItem.logic_id && '0 0.7rem 0 0.7rem' }}>{subItem.logic_id && (subItem.logic_id === 1 ? 'AND' : 'OR')}</span>  {item.filter_display_name} {getConditionOperator(subItem.condition_display_name)} {subItem.values}</span>
                      ))
                    ))
                    :
                    cohortDetails.appliedFilter?.length > 0 && cohortDetails.appliedFilter.map((item) => (
                      <>
                        <span>
                          {/* <span style={{ color: '#FB8E0D', margin: item.conditionAndOr !== '' && '0 0.7rem 0 0.7rem' }}>{item.conditionAndOr !== '' && (item.conditionAndOr === '1' ? 'AND' : 'OR')}</span> */}
                          {getFilterLabel(item.filterValue)} {getConditionOperator(item?.condition?.condition_display_name)} {item.value}
                        </span>
                        {
                          item.more.map((subItem) => (
                            <span>
                              <span style={{ color: '#FB8E0D', margin: subItem.conditionAndOr !== '' && '0 0.7rem 0 0.7rem' }}>{subItem.conditionAndOr !== '' && (subItem.logic_id === '1' ? 'AND' : 'OR')}</span>
                              {getFilterLabel(item.filterValue)} {getConditionOperator(subItem?.condition?.condition_display_name)} {subItem.value}
                            </span>
                          ))
                        }
                        <span style={{ color: '#FB8E0D', margin: item.conditionAndOr !== '' && '0 0.7rem 0 0.7rem' }}>{item.conditionAndOr !== '' && (item.conditionAndOr === '1' ? 'AND' : 'OR')}</span>
                      </>
                    ))
                }
              </div>

            </CardContent>
          </Card>
          <Typography style={{ fontSize: '0.9rem', color: "#1F71BA", position: 'relative', bottom: '2rem', left: '0.5rem' }}>Retailer Data Preview</Typography>
        </div>

        {/* Vertical Divider */}
        <Divider orientation="vertical" flexItem style={{ margin: '2rem 0 2rem 0' }} />

        {/* Right Half */}
        <div style={{ flex: 1, width: '50%', overflowWrap: 'break-word' }}>
          <Card style={{ borderRadius: '0rem 1rem 0rem 0rem', boxShadow: 'none', height: "100%" }}>
            <CardContent>

              <Box className="summary-kpi-container">
                {kpis?.length > 0 && <>
                  <div className='summary-kpi-item' style={{ background: '#FAAD2F' }}>
                    <div className='summary-card-text-container'>
                      <span className='summary-kpi-card-texts'>{convertLongNumberIntoSmallWithUnit(cohortDetails?.retailer_count)}</span>
                      <span className='summarykpi-card-label'>Retailer Count</span>
                    </div>
                  </div>
                  {
                    kpis.map((kpi, kpiIndex) => (
                      <div className='summary-kpi-item' style={{ background: '#FAAD2F' }}>
                        <div className='summary-card-text-container'>
                          <span className='summary-kpi-card-texts'>{convertLongNumberIntoSmallWithUnit(kpi.value)}</span>
                          <span className='summary-kpi-card-label'>{kpi.display_text}</span>
                        </div>
                      </div>
                    ))
                  }
                </>}
              </Box>
              {/* <Box className="summary-kpi-container">
              {
                kpis.map((kpi) => (
                  <div className="summary-kpi-item">
                    <span className="summary-kpi-item-label">{kpi.display_text}</span>
                    <span className="summary-kpi-item-value">{kpi.value}</span>
                  </div>
                ))
              }
            </Box> */}
            </CardContent>
            <Snackbar open={openDownload} autoHideDuration={3000} onClose={handleDownloadClose}>
              <Alert onClose={handleDownloadClose} severity="success" sx={{ width: '100%' }}>
                The cohort has been downloaded successfully !!
              </Alert>
            </Snackbar>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ViewResultCard;
