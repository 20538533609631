import React from 'react'
import '../Dashboard.css';
import { Button, Grid } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

const brandsFilterData = {
    ourBrands: [
        {
            brand_name: 'PAN MPS O',
            key: 1
        },
        {
            brand_name: 'MUCAINE',
            key: 2
        },
        {
            brand_name: 'Gelucil MPS',
            key: 3
        },
        {
            brand_name: 'DIGENE',
            key: 4
        },
        {
            brand_name: 'ACINIL O',
            key: 5
        },
        {
            brand_name: 'XYZ',
            key: 6
        },
        {
            brand_name: 'ABC',
            key: 7
        },
    ],
    subgroups: [
        {
            sub_brand_name: 'ABC',
            key: 1
        },
        {
            sub_brand_name: 'Sub MUCAINE',
            key: 2
        },
        {
            sub_brand_name: 'Gelucil MPS',
            key: 3
        },
        {
            sub_brand_name: 'DIGENE',
            key: 4
        },
        {
            sub_brand_name: 'ACINIL O',
            key: 5
        },
        {
            sub_brand_name: 'XYZ',
            key: 6
        },
        {
            sub_brand_name: 'ABC',
            key: 7
        },
    ],
    competitorBrands: [
        {
            competitor_brand_name: 'All Brands',
            key: 1
        },
        {
            competitor_brand_name: 'MUCAINE',
            key: 2
        },
        {
            competitor_brand_name: 'Gelucil MPS',
            key: 3
        },
        {
            competitor_brand_name: 'DIGENE',
            key: 4
        },
        {
            competitor_brand_name: 'ACINIL O',
            key: 5
        },
        {
            competitor_brand_name: 'XYZ',
            key: 6
        },
        {
            competitor_brand_name: 'ABC',
            key: 7
        },
    ],
    mainBrain: {
        brand_name: 'MUCAINE',
        key: 1,
    }
}

const BrandsFilter = (props) => {
    const { setIsBrandFilterOpen, brandFilterSelection, setBrandFilterSelection } = props;
    const [brandsFilter, setBrandsFilter] = React.useState(brandsFilterData);
    const [brandSelectionTemp, setBrandSelectionTemp] = React.useState({
        brand: '',
        subgroup: '',
        competitorBrand: [],
    });

    const handleCheckFilter = (event, itemIndex, check = 'our_brands') => {
        const brandsFilterCp = JSON.parse(JSON.stringify(brandsFilter));
        if (check === 'our_brands') {
            if (brandsFilterCp.ourBrands[itemIndex]?.isChecked) {
                brandsFilterCp.ourBrands[itemIndex].isChecked = false;
            } else {
                brandsFilterCp.ourBrands[itemIndex].isChecked = true;
            }
        } else if (check === 'subgroups') {
            if (brandsFilterCp.subgroups[itemIndex]?.isChecked) {
                brandsFilterCp.subgroups[itemIndex].isChecked = false;
            } else {
                brandsFilterCp.subgroups[itemIndex].isChecked = true;
            }
        } else if (check === 'competitor_brands') {
            if (brandsFilterCp.competitorBrands[itemIndex]?.isChecked) {
                brandsFilterCp.competitorBrands[itemIndex].isChecked = false;
            } else {
                brandsFilterCp.competitorBrands[itemIndex].isChecked = true;
            }
            if (event.target.checked) {
                setBrandSelectionTemp({
                    ...brandSelectionTemp,
                    'competitorBrand': [...brandSelectionTemp.competitorBrand, event.target.value]
                });
            } else {
                const brandSelectionTempCp = JSON.parse(JSON.stringify(brandSelectionTemp));
                brandSelectionTempCp.competitorBrand = brandSelectionTemp.competitorBrand.filter((x) => x !== event.target.value);
                setBrandSelectionTemp(brandSelectionTempCp);
            }
        }
        setBrandsFilter(brandsFilterCp);
    }

    const handleFinalSelect = (status = 'Apply') => {
        if (status === 'Apply') {
            setBrandFilterSelection(brandSelectionTemp);
            setIsBrandFilterOpen(null);
        } else {
            setBrandSelectionTemp({
                brand: '',
                subgroup: '',
                competitorBrand: [],
            });
            setBrandFilterSelection({
                brand: '',
                subgroup: '',
                competitorBrand: [],
            });
            const brandsFilterCp = JSON.parse(JSON.stringify(brandsFilter));
            brandsFilterCp.competitorBrands.forEach((item) => item.isChecked = false);
            setBrandsFilter(brandsFilterCp);
        }
    }

    return (
        <div style={{ background: '#FFF', height: '70vh', width: '97%', paddingBottom: '1rem', paddingTop: '0.5rem', paddingLeft: '0.5rem' }}>
            <div className='brand-filter-heading'>
                <span>Brands</span>
                <span style={{ cursor: 'pointer' }} onClick={() => setIsBrandFilterOpen(null)}><CloseIcon /></span>
            </div>
            <div className='brand-filter-sub-heading'>Please Select Brands</div>
            <Grid container spacing={2} style={{ paddingTop: '0.5rem', display: 'flex', justifyContent: 'center' }}>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <label className='brand-filter-labels'>Our Brands</label>
                    <div className='brand-sub-container'>

                        <TextField
                            id="input-with-icon-textfield"
                            label=""
                            name="brand"
                            placeholder="Search Brand"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                '& .css-1ixds2g, & .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input, & .css-o9qtno-MuiFormControl-root-MuiTextField-root': {
                                    padding: '4.5px 4px',
                                    fontSize: '14px'
                                },
                                '& .css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root': {
                                    background: 'white'
                                },
                                // marginBottom: '1rem',
                            }}
                        />
                        <RadioGroup
                            style={{ overflowY: 'auto', display: 'grid' }}
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={brandSelectionTemp.brand}
                            onChange={(event) => setBrandSelectionTemp({
                                ...brandSelectionTemp,
                                'brand': event.target.value
                            })}
                        >
                            {
                                brandsFilter.ourBrands.map((item, itemIndex) => (
                                    <FormControlLabel value={item.brand_name} control={<Radio sx={{
                                        '& .MuiSvgIcon-root': {
                                            fontSize: 18,
                                        }
                                    }} />}
                                        label={item.brand_name}
                                        sx={{
                                            '& .MuiTypography-root': {
                                                fontSize: '0.8rem'
                                            },
                                            marginLeft: '0',
                                        }} />
                                ))
                            }
                        </RadioGroup>
                    </div>
                </Grid>

                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <label className='brand-filter-labels'>Our Subgroups</label>
                    <div className='brand-sub-container'>

                        <TextField
                            id="input-with-icon-textfield"
                            label=""
                            name="brand"
                            placeholder="Search Subgroups"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                '& .css-1ixds2g, & .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input, & .css-o9qtno-MuiFormControl-root-MuiTextField-root': {
                                    padding: '4.5px 4px',
                                    fontSize: '14px'
                                },
                                '& .css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root': {
                                    background: 'white'
                                },
                                // marginBottom: '1rem',
                            }}
                        />

                        {brandSelectionTemp.brand !== '' &&
                            <RadioGroup
                                style={{ overflowY: 'auto', display: 'grid' }}
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={brandSelectionTemp.subgroup}
                                onChange={(event) => setBrandSelectionTemp({
                                    ...brandSelectionTemp,
                                    'subgroup': event.target.value
                                })}
                            >
                                {
                                    brandsFilter.subgroups.map((item, itemIndex) => (
                                        <FormControlLabel value={item.sub_brand_name} control={<Radio sx={{
                                            '& .MuiSvgIcon-root': {
                                                fontSize: 18,
                                            }
                                        }} />}
                                            label={item.sub_brand_name}
                                            sx={{
                                                '& .MuiTypography-root': {
                                                    fontSize: '0.8rem'
                                                },
                                                marginLeft: '0',
                                            }} />
                                    ))
                                }
                            </RadioGroup>
                        }

                    </div>
                </Grid>

                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <label className='brand-filter-labels'>Competitor Brands</label>
                    <div className='brand-sub-container'>

                        <TextField
                            id="input-with-icon-textfield"
                            label=""
                            name="brand"
                            placeholder="Search Competitor Brand"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                '& .css-1ixds2g, & .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input, & .css-o9qtno-MuiFormControl-root-MuiTextField-root': {
                                    padding: '4.5px 4px',
                                    fontSize: '14px'
                                },
                                '& .css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root': {
                                    background: 'white'
                                },
                            }}
                        />
                        {brandSelectionTemp.subgroup !== '' &&
                            <FormGroup style={{ marginLeft: '1rem', overflowY: 'auto', display: 'grid' }}>
                                {
                                    brandsFilter.competitorBrands.map((item, itemIndex) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={item?.isChecked}
                                                    onClick={(e) => handleCheckFilter(e, itemIndex, 'competitor_brands')}
                                                    value={item.competitor_brand_name}
                                                // onClick={handleCompetitorBranch}
                                                />
                                            }
                                            label={item.competitor_brand_name}
                                            sx={{
                                                '& .css-ahj2mt-MuiTypography-root': {
                                                    fontSize: '0.8rem',
                                                },
                                                '& .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root': {
                                                    padding: '6px 9px 6px 9px'
                                                },
                                                '& .css-i4bv87-MuiSvgIcon-root': {
                                                    height: '0.8em'
                                                }
                                            }} />
                                    ))
                                }
                            </FormGroup>
                        }
                    </div>
                </Grid>
            </Grid>

            <div className='brand-filter-btn-container'>
                <Button className='brand-filter-reset-btn' onClick={() => handleFinalSelect('Reset')}>Reset</Button>
                <Button className='brand-filter-apply-btn' onClick={() => handleFinalSelect('Apply')}>Apply</Button>
            </div>
        </div >
    )
}

export default BrandsFilter
