import React from 'react';
import './Loader.css';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const Loader = ({ loading }) => {
    return (
        <div>
            {loading && (
                <Box sx={{ display: 'flex' }}>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}>
                        <CircularProgress color="success" />
                    </Backdrop>
                </Box>
            )}
        </div>
    );
};

export default Loader;
