import React from 'react';
import ChartWrapper from '../ChartWrapper';
export default function BarChart(props) {
  const { google, chartData, alwaysOutside = false, height = 350, vAxisTitle = "", hAxisTitle = "" } = props;

  return (
    <div id='bar-chart'>
      <ChartWrapper
        google={google}
        chartType="BarChart"
        loader={<div>Loading Chart</div>}
        options={{
          title: '',
          legend: 'none',
          isStacked: true,
          // isStacked: "percent",
          bar: { groupWidth: '80%' },
          tooltip: { isHtml: true },
          // tooltip: { text: 'percentage' },
          // tooltip: { trigger: 'both' },
          series: {
            0: { color: '#5892F9' },
            1: { color: '#E8F0FB', enableInteractivity: false },
          },
          height: height,
          // width: window.innerWidth / 3,
          // bar: { gap: '20%' },
          chartArea: {
            height: '80%', top: 10, left: 70, bottom: 10, right: 35
          },
          vAxis: {
            textStyle: {
              color: '#15284B',
              fontSize: 11,
            },
            title: vAxisTitle,
            titleTextStyle: { color: 'black', italic: false, bold: true, },
          },
          hAxis: {
            baselineColor: '#8992A1',
            textPosition: 'none',
            gridlines: {
              color: "none"
            },
            title: hAxisTitle,
            titleTextStyle: { color: 'black', italic: false, bold: true, },
          },
          annotations: {
            alwaysOutside: alwaysOutside,
            textStyle: {
              color: '#616E84',
              fontSize: 12,
            },
          },
        }}
        chartData={chartData}
      />
    </div>
  );
}
