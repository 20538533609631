import { axiosNormal } from './config';

// DisplayTable.jsx and DynamicQueryBuilder.jsx
const _GetCohortListData = (payload) => axiosNormal.post('/api/cohort/list', payload);
const _DeleteCohortListData = (cohort_id) => axiosNormal.delete(`/api/cohort/delete/${cohort_id}`);
const _GetCohortDetail = (cohort_id) => axiosNormal.get(`/api/cohort/fetch/${cohort_id}`);
const _DownloadCohortDetail = (cohort_id) => axiosNormal.get(`/api/cohort/download/${cohort_id}`);
const _GetRetailerCount = (payload) => axiosNormal.post('/api/cohort/retailer-count', payload);


// LandingPage.jsx
const _GetMetricsData = (config) => axiosNormal.get('/api/dashboard/metrics', config);

// QueryBuilder.jsx
const _GetFilters = () => axiosNormal.get('/api/master/filters');
const _GetValueOptions = (filterId, search_str) => axiosNormal.get(`/api/master/dropdown-values?filter_id=${filterId}&search_str=${search_str}`);
const _GetConditionsOptions = (filterId) => axiosNormal.get(`/api/master/conditions?filter_id=${filterId}`);

// TabTable.jsx
const _GetRecentlyCreatedAndMostDownloadedData = (payload) => axiosNormal.post('/api/cohort/list', payload);
const _GetPreviewRetailer = (payload) => axiosNormal.post('/api/cohort/preview-retailers', payload);
const _CreateCohort = (payload) => axiosNormal.post('/api/cohort/create', payload);
const _GetSegmentKpis = (payload) => axiosNormal.post('/api/cohort/segment-kpis', payload);


// AutomatedSegment.jsx
const _GetTop5Companies = () => axiosNormal.get('/api/auto-seg/top-companies');
const _GetTop5Brands = () => axiosNormal.get('/api/auto-seg/top-brands');
const _GetTop5Products = () => axiosNormal.get('/api/auto-seg/top-products');
const _GetTop5Regions = () => axiosNormal.get('/api/auto-seg/top-cities');
const _GetOrderCountWiseRetailers = () => axiosNormal.get('/api/auto-seg/order-bucket-wise-retailers');
const _GetSegmentWiseContribution = () => axiosNormal.get('/api/auto-seg/segment-wise-contribution');
const _GetSalesValue = () => axiosNormal.get('/api/auto-seg/month-on-month-orders-and-gmv');

const API = {
    _GetCohortListData,
    _DeleteCohortListData,
    _GetCohortDetail,
    _DownloadCohortDetail,
    _GetMetricsData,
    _GetFilters,
    _GetValueOptions,
    _GetConditionsOptions,
    _GetRecentlyCreatedAndMostDownloadedData,
    _GetRetailerCount,
    _GetPreviewRetailer,
    _CreateCohort,
    _GetTop5Companies,
    _GetTop5Brands,
    _GetTop5Regions,
    _GetTop5Products,
    _GetOrderCountWiseRetailers,
    _GetSegmentWiseContribution,
    _GetSalesValue,
    _GetSegmentKpis
};

export default API;