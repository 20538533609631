import React from 'react'
import './AutomatedSegments.css';
import { Grid } from '@material-ui/core';
import SegmentationImage from '../../Assets/PeopleSegmentation.png'
import { AutomatedSegmentsKPI, AutomatedSegmentsKpiDescription, MonthInShortForm, kpis } from '../../Components/contants/app_contants';
import LineChart from '../../Components/charts/utils/LineChart';
import useGoogleCharts from '../../Components/charts/useGoogleChart';
import BarChart from '../../Components/charts/utils/BarChart';
import API from '../../Components/config/API';
import Loader from '../../Components/loader/Loader';
import { convertLongNumberIntoSmallWithUnit, formatNumber } from '../../Components/config/helper';
import { Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

const top5Headers = ['Top 5 Companies', 'Top 5 Brands', 'Top 5 Regions', 'Top 5 Products']

const AutomatedSegments = () => {
    const google = useGoogleCharts();
    const navigate = useNavigate();
    const [loadingCount, setLoadingCount] = React.useState(0);
    const [kpiSelected, setKpiSelected] = React.useState(kpis[0].key);
    const [top5Companies, setTop5Companies] = React.useState({});
    const [top5Brands, setTop5Brands] = React.useState({});
    const [top5Regions, setTop5Regions] = React.useState({});
    const [top5Products, setTop5Products] = React.useState({});
    const [salesValue, setSalesValue] = React.useState({});
    const [orderCountWiseRetailer, setOrderCountWiseRetailer] = React.useState({});
    const [segmentWiseContribution, setSegmentWiseContribution] = React.useState({});

    const handleRefactorResponse = (response) => {
        const bulkBuyers = response.find((obj) => obj.segment?.toLowerCase() === AutomatedSegmentsKPI.BulkBuyers)?.values;
        const impulsiveBuyers = response.find((obj) => obj.segment?.toLowerCase() === AutomatedSegmentsKPI.ImpulsiveBuyers)?.values;
        const indifferentBehaviourBuyers = response.find((obj) => obj.segment?.toLowerCase() === AutomatedSegmentsKPI.IndifferentBehaviourBuyers)?.values;
        const rarePurchasers = response.find((obj) => obj.segment?.toLowerCase() === AutomatedSegmentsKPI.RarePurchasers)?.values;
        const schemeseekerAndBenefitDriven = response.find((obj) => obj.segment?.toLowerCase() === AutomatedSegmentsKPI.SchemeseekerAndBenefitDriven)?.values;
        const others = response.find((obj) => obj.segment?.toLowerCase() === AutomatedSegmentsKPI.Others)?.values;

        const updatedResponse = {
            BulkBuyers: bulkBuyers,
            ImpulsiveBuyers: impulsiveBuyers,
            IndifferentBehaviourBuyers: indifferentBehaviourBuyers,
            RarePurchasers: rarePurchasers,
            SchemeseekerAndBenefitDriven: schemeseekerAndBenefitDriven,
            Others: others
        }

        return updatedResponse;
    }

    const handleTransformUpdatedResponse = (response, color, uniqueKey1, uniqueKey2, uniqueKey3, transformOn = '') => {
        const updatedResponse = {};
        if (transformOn === '') {
            Object.keys(response).forEach((key, keyIndex) => {
                const chartData = [
                    ['Key', uniqueKey2 === 'retailers' ? 'Retailers' : 'GMV', { role: 'style' }, { role: 'annotation' }],
                    ['', '', color, ''],
                    ['', '', color, ''],
                    ['', '', color, ''],
                    ['', '', color, ''],
                    ['', '', color, ''],
                ]
                chartData.forEach((item, itemIndex) => {
                    if (itemIndex > 0) {
                        item[0] = response[key][itemIndex - 1][uniqueKey1]
                        item[1] = response[key][itemIndex - 1][uniqueKey2]
                        // item[3] = formatNumber(response[key][itemIndex - 1][uniqueKey3], 1)
                        item[3] = `${response[key][itemIndex - 1][uniqueKey3]}%`

                    }
                })
                updatedResponse[key] = chartData;
            })
        } else {
            Object.keys(response).forEach((key, keyIndex) => {
                const chartData = [
                    ['Key', 'GMV', { role: 'annotation' }],
                    ['', '', ''],
                    ['', '', ''],
                    ['', '', ''],
                    ['', '', ''],
                    ['', '', ''],
                    ['', '', ''],
                ];
                chartData.forEach((item, itemIndex) => {
                    if (itemIndex > 0) {
                        const monthYear = response[key][itemIndex - 1][uniqueKey1].split('-');
                        const year = monthYear[0].toString().slice(-2);
                        const month = MonthInShortForm[parseInt(monthYear[1]) - 1 ]
                        item[0] = month + '-' + year;
                        item[1] = response[key][itemIndex - 1][uniqueKey2]
                    }
                });
                updatedResponse[key] = chartData;
            })
        }

        return updatedResponse;
    }

    const handleGetTop5Companies = () => {
        API._GetTop5Companies().then((response) => {
            const updatedResponse = handleRefactorResponse(response.data.data);
            const finalResponse = handleTransformUpdatedResponse(updatedResponse, 'rgb(157 184 207)', 'company', 'gmv', 'gmv_perc');
            setTop5Companies(finalResponse);
            setLoadingCount((prevState) => prevState + 1);
        })
            .catch((error) => {
                console.error('Error:', error);
                setLoadingCount((prevState) => prevState + 1);
            });
    }
    const handleGetTop5Brands = () => {
        API._GetTop5Brands().then((response) => {
            const updatedResponse = handleRefactorResponse(response.data.data);
            const finalResponse = handleTransformUpdatedResponse(updatedResponse, '#fbc055', 'brand', 'gmv', 'gmv_perc');
            setTop5Brands(finalResponse);
            setLoadingCount((prevState) => prevState + 1);
        })
            .catch((error) => {
                console.error('Error:', error);
                setLoadingCount((prevState) => prevState + 1);
            });
    }
    const handleGetTop5Regions = () => {
        API._GetTop5Regions().then((response) => {
            const updatedResponse = handleRefactorResponse(response.data.data);
            const finalResponse = handleTransformUpdatedResponse(updatedResponse, 'rgb(157 184 207)', 'region', 'gmv', 'gmv_perc');
            setTop5Regions(finalResponse);
            setLoadingCount((prevState) => prevState + 1);
        })
            .catch((error) => {
                console.error('Error:', error);
                setLoadingCount((prevState) => prevState + 1);
            });
    }
    const handleGetTop5Products = () => {
        API._GetTop5Products().then((response) => {
            const updatedResponse = handleRefactorResponse(response.data.data);
            const finalResponse = handleTransformUpdatedResponse(updatedResponse, '#fbc055', 'product', 'gmv', 'gmv_perc');
            setTop5Products(finalResponse);
            setLoadingCount((prevState) => prevState + 1);
        })
            .catch((error) => {
                console.error('Error:', error);
                setLoadingCount((prevState) => prevState + 1);
            });
    }
    const handleCountWiseRetailers = () => {
        API._GetOrderCountWiseRetailers().then((response) => {
            const updatedResponse = handleRefactorResponse(response.data.data);
            const finalResponse = handleTransformUpdatedResponse(updatedResponse, 'rgb(157 184 207)', 'order_bucket', 'retailers', 'retailers_perc');
            setOrderCountWiseRetailer(finalResponse);
            setLoadingCount((prevState) => prevState + 1);
        })
            .catch((error) => {
                console.error('Error:', error);
                setLoadingCount((prevState) => prevState + 1);
            });
    }

    const handleTransformSegmentWiseContribution = (response) => {
        const updatedResponse = {};
        Object.keys(AutomatedSegmentsKPI).forEach((key) => {
            const obj = response.find((x) => x.segment?.toLowerCase() === AutomatedSegmentsKPI[key]);
            updatedResponse[key] = obj;
        });

        return updatedResponse;
    }
    const handleSegmentWiseContribution = () => {
        API._GetSegmentWiseContribution().then((response) => {
            const updatedResponse = handleTransformSegmentWiseContribution(response.data.data);
            setSegmentWiseContribution(updatedResponse);
            setLoadingCount((prevState) => prevState + 1);
        })
            .catch((error) => {
                console.error('Error:', error);
                setLoadingCount((prevState) => prevState + 1);
            });
    }

    const handleSalesValue = () => {
        API._GetSalesValue().then((response) => {
            const updatedResponse = handleRefactorResponse(response.data.data);
            const finalResponse = handleTransformUpdatedResponse(updatedResponse, '', 'month', 'gmv', '', 'salesValue');
            setSalesValue(finalResponse);
            setLoadingCount((prevState) => prevState + 1);
        })
            .catch((error) => {
                console.error('Error:', error);
                setLoadingCount((prevState) => prevState + 1);
            });
    }

    React.useEffect(() => {
        const handleGetAllKpisData = async () => {
            handleGetTop5Companies();
            handleGetTop5Brands();
            handleGetTop5Regions();
            handleGetTop5Products();
            handleCountWiseRetailers();
            handleSegmentWiseContribution();
            handleSalesValue();
        }
        handleGetAllKpisData();
    }, []);

    return (
        <div style={{ padding: '0.5rem 0 0.5rem 0.5rem', height: loadingCount !== 7 && '100vh', background: 'rgb(226 223 223)' }}>
            <Loader loading={!(loadingCount === 7)} />
            {/* <h3 className='AS-heading'>Automated Segments</h3> */}

            <div style={{ marginRight: '0.8rem', float: 'right' }}>
                {/* <Button variant="outlined"
                    sx={{ color: "black", fontSize: "1rem", textTransform: 'Capitalize', marginLeft: "1.5rem", width: '6rem', height: '2rem' }}
                    onClick={() => { navigate('/') }}
                >
                    <ArrowBackIcon />Back
                </Button> */}
                <Button className="btn-style" onClick={() => { navigate('/') }} sx={{ backgroundColor: '#4591D5', width: '6rem', marginLeft: "1.5rem" }} variant="contained" size="small" style={{ textTransform: 'Capitalize' }} >
                    <ArrowBackIcon />Back
                </Button>
            </div>


            {(loadingCount === 7) &&
                <>
                    <Grid container spacing={1} xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', justifyContent: 'center', marginTop: '0.5rem' }}>
                        {
                            kpis.map((kpi) => (
                                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => setKpiSelected(kpi.key)}>
                                    <div className='AO-card-container' style={{
                                        background: kpiSelected === kpi.key && '#1439BB',
                                        color: kpiSelected === kpi.key && 'white'
                                    }}>
                                        <div className='card-text-container'>
                                            <span className='card-texts' style={{ fontSize: kpiSelected === kpi.key && '0.95rem', fontWeight: '600' }}>{kpi.label}</span>
                                        </div>
                                    </div>
                                </Grid>
                            ))
                        }
                    </Grid>

                    <Grid container spacing={1} xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: '0.5rem' }}>

                        <Grid container xs={3} sm={3} md={3} lg={3} xl={3} style={{ width: '100%' }}>
                            <div className='chart-container' style={{ marginTop: '0', padding: 0, width: '100%' }}>
                                <div style={{ textAlign: 'center' }}>
                                    <img src={SegmentationImage} alt='' height='85%' width='85%' />
                                </div>
                                <div className='orderCountWiseRetailerHeader'>About the Segment</div>
                                <div style={{
                                    paddingRight: '1rem',
                                    // height: '65vh', overflowY: 'auto' 
                                }}>
                                    <ul style={{ fontSize: '14px', lineHeight: '1.5rem' }}>
                                        {
                                            AutomatedSegmentsKpiDescription[kpiSelected].map((item) => (
                                                <li>{item}</li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                        </Grid>


                        <Grid container xs={9} sm={9} md={9} lg={9} xl={9} style={{ paddingLeft: '0.3rem', width: '100%' }}>

                            <Grid container spacing={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                                {Object.keys(segmentWiseContribution)?.length > 0 &&
                                    <>
                                        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                                            <div className='KPI-card-container'>
                                                <div className='card-text-container'>
                                                    <span className='kpi-card-texts'>{convertLongNumberIntoSmallWithUnit(segmentWiseContribution[kpiSelected].gmv)}</span>
                                                    <span className='kpi-card-label'>GMV (in Cr)</span>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                                            <div className='KPI-card-container'>
                                                <div className='card-text-container'>
                                                    <span className='kpi-card-texts'>{convertLongNumberIntoSmallWithUnit(segmentWiseContribution[kpiSelected].orders)}</span>
                                                    <span className='kpi-card-label'>Orders</span>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                                            <div className='KPI-card-container'>
                                                <div className='card-text-container'>
                                                    <span className='kpi-card-texts'>{convertLongNumberIntoSmallWithUnit(segmentWiseContribution[kpiSelected].retailers)}</span>
                                                    <span className='kpi-card-label'>Retailers</span>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                                            <div className='KPI-card-container'>
                                                <div className='card-text-container'>
                                                    <span className='kpi-card-texts'>{convertLongNumberIntoSmallWithUnit(segmentWiseContribution[kpiSelected].distributors)}</span>
                                                    <span className='kpi-card-label'>Distributors</span>
                                                </div>
                                            </div>
                                        </Grid>
                                    </>

                                }
                            </Grid>

                            <Grid container spacing={1} xs={12} sm={12} md={12} lg={12} xl={12}>

                                <Grid item xs={7} sm={7} md={7} lg={7} xl={7} >
                                    <div className='chart-container'>
                                        <div className='orderCountWiseRetailerHeader'>Month-on-Month GMV</div>
                                        <div style={{ overflow: 'hidden' }}>
                                            {
                                                Object.keys(salesValue)?.length > 0 &&
                                                <LineChart google={google} title=""
                                                    vAxisTitle="GMV"
                                                    hAxisTitle="Month" chartData={salesValue[kpiSelected]} width={'100%'} height={269} />

                                            }
                                        </div>
                                    </div>


                                </Grid>

                                <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                                    <div className='chart-container'>
                                        <div className='orderCountWiseRetailerHeader' style={{ padding: '0.3rem' }}>Order Count wise Retailers</div>
                                        <div style={{ overflow: 'hidden' }}>
                                            {Object.keys(orderCountWiseRetailer)?.length > 0 && 
                                            <BarChart google={google} chartData={orderCountWiseRetailer[kpiSelected]} alwaysOutside={true} height={268} vAxisTitle="Orders"
                                            hAxisTitle="Retailers" />
                                            }
                                        </div>
                                    </div>
                                </Grid>

                            </Grid>


                            <Grid container spacing={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                                {
                                    top5Headers.map((header, objIndex) => (
                                        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                                            <div className='chart-container'>
                                                <div className='orderCountWiseRetailerHeader' style={{ marginBottom: '1rem' }}>{header}</div>
                                                {
                                                    objIndex === 0 ?
                                                        Object.keys(top5Companies)?.length > 0 &&
                                                        <BarChart google={google} chartData={top5Companies[kpiSelected]} height={250} />
                                                        :
                                                        objIndex === 1 ?
                                                            Object.keys(top5Brands)?.length > 0 &&
                                                            <BarChart google={google} chartData={top5Brands[kpiSelected]} height={250} />
                                                            :
                                                            objIndex === 2 ?
                                                                Object.keys(top5Regions)?.length > 0 &&
                                                                <BarChart google={google} chartData={top5Regions[kpiSelected]} height={250} />
                                                                :
                                                                objIndex === 3 ?
                                                                    Object.keys(top5Products)?.length > 0 &&
                                                                    <BarChart google={google} chartData={top5Products[kpiSelected]} height={250} />
                                                                    : null
                                                }
                                            </div>
                                        </Grid>
                                    ))
                                }
                            </Grid>


                        </Grid>


                    </Grid>
                </>
            }
        </div>
    )
}

export default AutomatedSegments
