import React from 'react';
import { useTheme } from '@mui/material/styles';
import ChartWrapper from '../ChartWrapper';
import { colorPalette } from '../../contants/app_contants';

export default function LineChart(props) {
    const {
        google, chartData, unselectedIndexes, timeRange = 'DAILY', vAxisTitle, hAxisTitle,
        width = 1000, height = 400, title
    } = props;
    const theme = useTheme();

    // filtering colorPalette based on docType filter
    function filterPalette(palette, filterIndexes) {
        return palette.filter((_, index) => !filterIndexes?.includes(index));
    }
    const filteredColorPalette = filterPalette(colorPalette, unselectedIndexes);
    const createSeries = (colors) => {
        const series = {};
        colors.forEach((color, index) => {
            series[index] = { color };
        });
        return series;
    };

    return (
        <ChartWrapper
            google={google}
            chartType="LineChart"
            options={{
                title: title,
                series: createSeries(filteredColorPalette),
                width: width,
                height: height,
                curveType: 'function',
                // bar: { groupWidth: '50%' },
                tooltip: { isHtml: true },
                isStacked: 'percent',
                legend: 'none',
                chartArea: {
                    // height: '100%',
                    left: 50,
                    right: 0,
                    top:30
                },
                hAxis: {
                    minValue: 0,
                    slantedText: timeRange === 'DAILY' && true,
                    slantedTextAngle: timeRange === 'DAILY' && '45%',
                    title: hAxisTitle,
                    titleTextStyle: { color: 'black', italic: false, bold: true, },
                    textStyle: { color: theme.palette.grayDark2, fontSize: 12 },
                },
                vAxis: {
                    title: vAxisTitle,
                    baselineColor: theme.palette.grayMid4,
                    titleTextStyle: { color: 'black', italic: false, bold: true,},
                    textStyle: { color: theme.palette.grayDark2, bold: true, fontSize: 12 },
                    // format: '#\'%\'',
                    // viewWindow: {
                    //     max: 100, // Set the maximum value on the Y-axis to 100%
                    // },
                    // minValue: 0,
                    // maxValue: 1,
                    // ticks: [0, 30, 60, 90, 100]
                },
            }}
            chartData={chartData}
        />
    );
}