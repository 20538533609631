import React from 'react'
import './SuggestionDropdown.css';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { FormControl } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Chip from '@mui/material/Chip';

// const useStyles = makeStyles((theme) => ({
//     smallInput: {
//         '& .MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
//             paddingTop: 2,
//             paddingBottom: 2,
//         },
//         '& .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root': {
//             lineHeight: '0.8em',
//         },
//     },
// }));

const useStyles = makeStyles((theme) => ({
    smallInput: (props) => ({
        '& .MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
            paddingTop: props.paddingTop,
            paddingBottom: props.paddingBottom,
        },
        '& .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root': {
            lineHeight: '0.8em',
            overflow: 'visible'
        },
    }),
}));

const SuggestionDropdown = (props) => {
    const {
        options,
        handleChange,
        value,
        width = "100%",
        marginTop = undefined,
        label,
        height = '2px',
        isMultiple = false,
    } = props;

    const customPadding = {
        paddingTop: height,
        paddingBottom: height,
    };

    const [newOptions, setNewOptions] = React.useState([]);
    const [newValue, setNewValue] = React.useState([]);
    React.useEffect(() => {
        if (isMultiple) {
            const res = [];
            options.forEach((item) => res.push({ key: item }));
            setNewOptions(res);
        }
    }, [isMultiple, options]);

    const classes = useStyles(customPadding);
    return (
        <FormControl
            sx={{ width, textAlign: 'left', marginTop: marginTop, background: 'white' }}
            className={classes.smallInput}
        >
            {
                !isMultiple ?
                    <Autocomplete
                        sx={{ background: 'white' }}
                        size="small"
                        value={value}
                        onInputChange={handleChange}
                        inputValue={value}
                        id="clear-on-escape"
                        clearOnEscape
                        options={options}
                        // renderInput={(params) => <TextField {...params} label={label} />}
                        renderInput={(params) => (
                            <TextField {...params} label={label} variant="standard" />
                        )}
                    />

                    :
                    newOptions?.length > 0 &&
                    <Autocomplete
                        multiple
                        id="tags-filled"
                        options={newOptions}
                        onInputChange={handleChange}
                        value={value !== '' ? value.split(',').map((d) => ({ key: d })) : []}
                        freeSolo
                        getOptionLabel={(option) => option.key}
                        onChange={handleChange}
                        renderTags={(val, getTagProps) => {
                            return val && val.map((option, index) => (
                                <Chip variant="outlined" label={option.key} {...getTagProps({ index })} />
                            ))
                        }
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label={label}
                                placeholder="Value"
                            />
                        )}
                    />
            }
        </FormControl>
    )
}

export default SuggestionDropdown
