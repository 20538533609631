import React, { useState, useEffect } from "react";
import { Typography, Box, Button, Paper } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import './LandingPage.css';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import { useNavigate } from "react-router-dom";
import TabTable from '../../Components/TabTable/TabTable';
import Displaytable from '../../Components/Table/Displaytable';
import Vectorimg from '../../Assets/Vectorimg.png';
import Grid from '@mui/material/Grid';
import API from "../../Components/config/API";
import Loader from "../../Components/loader/Loader";
import useGoogleCharts from '../../Components/charts/useGoogleChart';
import DonutChart from '../../Components/charts/utils/DonutChart';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

const { REACT_APP_BACKEND_URL } = process.env;
const LandingPage = ({ uname }) => {
  const navigate = useNavigate();
  const google = useGoogleCharts();
  const [cardData, setcardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [segmentWiseContribution, setSegmentWiseContribution] = useState([]);

  useEffect(() => {
    setLoading(true);
    API._GetMetricsData().then((response) => {
      setcardData(response.data);
      setLoading(false);
    })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);

  const handleSegmentWiseContribution = () => {
    setLoading(true);
    API._GetSegmentWiseContribution().then((response) => {
      const PieChartData = [
        ['GMV', 'Percentage'],
        ['', ''],
        ['', ''],
        ['', ''],
        ['', ''],
        ['', ''],
        ['', ''],
      ]
      PieChartData.forEach((item, itemIndex) => {
        if (itemIndex > 0) {
          item[0] = response.data.data[itemIndex - 1].segment;
          item[1] = response.data.data[itemIndex - 1].gmv_perc;
          // item[1] = `${response.data.data[itemIndex - 1].gmv_perc}%`;
        }
      });
      const arrayToMove = PieChartData.splice(3, 1)[0];
      PieChartData.push(arrayToMove);
      setSegmentWiseContribution(PieChartData);
      setLoading(false);

    })
      .catch((error) => {
        console.error('Error:', error);
        setLoading(false);
      });
  }

  React.useEffect(() => {
    handleSegmentWiseContribution();
  }, [])

  return (
    <div style={{ background: '#F3F6F9' }}>
      <Loader loading={loading} />
      <Button className="btn-style top-btns" sx={{ marginRight: "2.5%" }} size="small" onClick={() => { navigate('/newcohort') }}>
        <CreateNewFolderOutlinedIcon />Create Segment</Button>

      {/* <Button className="btn-style top-btns" sx={{ marginRight: "2.5%" }} size="small" onClick={() => { navigate('/automated-segments') }}>Automated Segment</Button> */}


      <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
        {/* <div className="parent-container"> */}
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <div className='container'>

            <div className="flex-container-second">
              <Card className='blue-gradient'>
                <Grid container style={{ display: 'grid' }}>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6} style={{ position: 'relative', left: '65%' }}>
                    <img src={Vectorimg} alt="logo" className='vector' height={'100px'} />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <CardContent sx={{ position: 'relative', bottom: '2rem', left: '1rem', padding: '16px 16px 16px 0' }}>
                      <Typography sx={{ color: 'white' }} variant="h3" component="div">
                        {cardData?.data ? cardData?.data?.total_cohorts : 0}
                      </Typography>
                      <Typography className='font-style' variant="body2">
                        Total Segments
                      </Typography>
                    </CardContent>
                  </Grid>
                </Grid>
              </Card>

              <Card className='blue-gradient'>
                <Grid container style={{ display: 'grid' }} direction='column'>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6} style={{ position: 'relative', left: '65%' }}>
                    <img src={Vectorimg} alt="logo" className='vector' height={'100px'} />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <CardContent sx={{ position: 'relative', bottom: '2rem', left: '1rem', padding: '16px 16px 16px 0' }}>
                      <Typography sx={{ color: 'white' }} variant="h3" component="div">
                        {cardData?.data ? cardData?.data?.created_last_30_days : 0}
                      </Typography>
                      <Typography className='font-style' variant="body2">
                        Segments Created in last 30 days
                      </Typography>
                    </CardContent>
                  </Grid>
                </Grid>
              </Card>

            </div>

            <div className="flex-container-second">
              <Card className='orange-gradient'>
                <Grid container style={{ display: 'grid' }} direction='column'>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6} style={{ position: 'relative', left: '65%' }}>
                    <img src={Vectorimg} alt="logo" className='vector' height={'100px'} />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <CardContent sx={{ position: 'relative', bottom: '2rem', left: '1rem', padding: '16px 16px 16px 0' }}>
                      <Typography sx={{ color: 'white' }} variant="h3" component="div">
                        {cardData?.data ? cardData?.data?.total_downloads : 0}
                      </Typography>
                      <Typography className='font-style' variant="body2">
                        Total Downloads
                      </Typography>
                    </CardContent>
                  </Grid>
                </Grid>

              </Card>

              <Card className='orange-gradient'>
                <Grid container style={{ display: 'grid' }} direction='column'>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6} style={{ position: 'relative', left: '65%' }}>
                    <img src={Vectorimg} alt="logo" className='vector' height={'100px'} />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <CardContent sx={{ position: 'relative', bottom: '2rem', left: '1rem', padding: '16px 16px 16px 0' }}>
                      <Typography sx={{ color: 'white' }} variant="h3" component="div">
                        {cardData?.data ? cardData?.data?.download_last_30_days : 0}
                      </Typography>
                      <Typography className='font-style' variant="body2">
                        Downloads in last 30 days
                      </Typography>
                    </CardContent>
                  </Grid>
                </Grid>
              </Card>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <div style={{ marginTop: '1rem', marginRight: '0.5rem' }}>
            {/* <TabTable></TabTable> */}
            <Paper elevation={3} className="pie-chart-container"
              style={{ minWidth: '44%', height: '23.7rem', margin: '1%', padding: '1rem', background: '#FFFFFF', borderRadius: '0.625rem' }}>
              {segmentWiseContribution?.length > 0 &&
                <>
                  <div className='pie-chart-header'>Automated Segments - GMV contribution</div>
                  <DonutChart google={google} title="" vAxisTitle=""
                    hAxisTitle="" chartData={segmentWiseContribution} legend={true}
                    width={500} height={250} />

                  <div style={{ display: 'grid', justifyContent: 'center' }}>
                    <span style={{ fontWeight: '500', color: '#5e5959', fontSize: '0.9rem' }}>To find more information, go to the Segmentation Dashboard</span>
                    <Button className="btn-style top-btns" sx={{ marginRight: "2.5%" }} size="small" onClick={() => { navigate('/automated-segments') }}>Segmentation Dashboard <ArrowRightAltIcon size="28px"/></Button>
                  </div>
                </>
              }

            </Paper>
          </div>
        </Grid>
        {/* </div> */}
      </Grid>

      <div style={{ margin: '0.9rem' }}>
        <Displaytable loading={loading} setLoading={setLoading} />
      </div>
    </div >
  );
}

export default LandingPage;
