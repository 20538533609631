import React from 'react';
import { useTheme } from '@mui/material/styles';
import ChartWrapper from '../ChartWrapper';
import { colorPalette } from '../../contants/app_contants';

export default function ColumnChart(props) {
    const {
        google, chartData, unselectedIndexes, timeRange, vAxisTitle
    } = props;
    const theme = useTheme();

    // filtering colorPalette based on docType filter
    function filterPalette(palette, filterIndexes) {
        return palette.filter((_, index) => !filterIndexes?.includes(index));
    }
    const filteredColorPalette = filterPalette(colorPalette, unselectedIndexes);
    const createSeries = (colors) => {
        const series = {};
        colors.forEach((color, index) => {
            series[index] = { color };
        });
        return series;
    };

    return (
        <ChartWrapper
            google={google}
            chartType="ColumnChart"
            options={{
                series: createSeries(filteredColorPalette),
                width: 1000,
                height: 400,
                bar: { groupWidth: '50%' },
                tooltip: { isHtml: true },
                isStacked: 'percent',
                legend: { position: 'top' },
                chartArea: {
                    height: timeRange !== 'DAILY' && '75%',
                    left: 70,
                    right: 0,
                },
                hAxis: {
                    minValue: 0,
                    slantedText: timeRange === 'DAILY' && true,
                    slantedTextAngle: timeRange === 'DAILY' && '45%',
                    title: timeRange,
                    titleTextStyle: { color: theme.palette.grayDark3 },
                    textStyle: { color: theme.palette.grayDark2, fontSize: 10 },
                },
                vAxis: {
                    title: vAxisTitle,
                    baselineColor: theme.palette.grayMid4,
                    titleTextStyle: { color: theme.palette.grayDark3 },
                    textStyle: { color: theme.palette.grayDark2, bold: true, fontSize: 12 },
                    format: 'percent',
                    minValue: 0,
                    ticks: [0, .3, .6, .9, 1]
                },
            }}
            chartData={chartData}
        />
    );
}