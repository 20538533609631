import React, { useState, useEffect } from "react";
import {
  AppBar,
  Box,
  Button,
  Toolbar,
  Typography,
} from "@mui/material";
import pharmaracklogo from "../../Assets/pharmaracklogo.png";
import pharmaracklogoNew from "../../Assets/pharmarack-logo.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LogoutIcon from "@mui/icons-material/Logout";
import { makeStyles } from '@material-ui/core/styles';
import LandingPage from "../../Pages/LandingPage/LandingPage";
import { useLocation, useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  gradientBackground: {
    // background: 'linear-gradient(to right, #FFFFFF,#d6d6d6, #4b62b2,#283f91,#1439BB)', // Customize the gradient colors
    background: 'linear-gradient(to right, #FFFFFF,#d6d6d6, #c6c7cb,#2a3f8c,#2a48b1)' // Customize the gradient colors
  },
}));



const Navbar = () => {
  const [value, setvalue] = useState([]);

  //Styling
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Box>
      <AppBar sx={{ position: "sticky", height: "70px" }} className={classes.gradientBackground} >
        <Toolbar>
          <Box padding={3} sx={{ display: 'flex', alignItems: 'center', cursor:'pointer' }} onClick={()=> navigate('/')}>
              <img
                src={pharmaracklogoNew}
                alt="logo"
                width='66px'
                height='40px'
              />
              <span style={{ color: '#4591d5', fontWeight: '700', fontSize: '1.4rem', marginBottom: '0.2rem' }}>pharmarack</span>
              <span style={{ fontWeight: '700', fontSize: '1.4rem', marginBottom: '0.2rem', borderRight: '3px solid #FAAD2F', padding: '1rem 0', marginRight: '0.4rem', marginLeft: '0.4rem' }}></span>
              <span style={{ color: 'orange', fontWeight: '700', fontSize: '1.4rem', marginBottom: '0.2rem' }}>{location.pathname === "/dashboard" ? 'Territory Health Index' : 'Audience Management Tool'}</span>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>

  );
};

export default Navbar;
