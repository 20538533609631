import { Card, Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import Loader from '../../Components/loader/Loader';
import { toast } from 'react-toastify';
import API from "../config/API";

const ViewResultTable = ({ cohortDetails }) => {
  const [viewdata, setviewdata] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [loading, setLoading] = useState(false);


  const pageLimit = 20;
  useEffect(() => {
    setLoading(true);
    const requestBody = {
      "page_limit": pageLimit,
      "rules": cohortDetails?.rules
    }
    API._GetPreviewRetailer(requestBody).then(({data}) => {
        setviewdata(data.data.retailers);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error:', error);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const tableColumns = ['Retailer ID', 'Retailer Name', 'Mobile', 'Email ID', 'Region', 'State']
  const columns = [
    {
      field: "retailer_id",
      headerName: "Retailer ID",
      width: 210,
      editable: false,
      sortable: false,
      headerClassName: 'super-app-theme--header',

    },
    {
      field: "retailer_name",
      headerName: "Retailer Name",
      width: 300,
      editable: false,
      sortable: false,
      headerClassName: 'super-app-theme--header',

    },
    // {
    //   field: "userId",
    //   headerName: "User ID",
    //   width: 200,
    //   editable: false,
    //   sortable: false,
    //   headerClassName:'super-app-theme--header',
    // },
    {
      field: "mobile_number",
      headerName: "Mobile",
      width: 300,
      editable: false,
      sortable: false,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: "email",
      headerName: "Email ID",
      width: 300,
      editable: false,
      sortable: false,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: "region_name",
      headerName: "Region",
      width: 180,
      editable: false,
      sortable: false,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: "state_name",
      headerName: "State",
      width: 167,
      editable: false,
      sortable: false,
      headerClassName: 'super-app-theme--header',
    },

  ];
  function getRowId(row) {
    return row.retailer_id;

  }
  return (
    
    <Card style={{ borderRadius: '0rem 0rem 1rem 1rem', width: '100%' }}>
      <Loader loading={loading} />
      {/* <div> */}
      {/* <Box sx={{  width: '100%' }}> */}
      {/* <DataGrid autoHeight 
        sx={{ '& .super-app-theme--header': {
          backgroundColor: '#CAD1D8'},}}
        rows={viewdata}
        columns={columns}
        rowsPerPageOptions={[pageLimit]}
        disableColumnMenu ={true}
        disableDensitySelector ={true}
        checkboxSelection={false}
        // getRowId={(rows) => rows?.id}
        getRowId={getRowId}
        
        /> */}
      {/* </div> */}
      {/* </Box> */}

      <Paper sx={{ width: '100%' }}>
        <TableContainer component={Paper}
          style={{ height: '52vh', overflowY: 'scroll' }}
        >
          <Table stickyHeader size="small" aria-label="a audit list">
            <TableHead sx={{
              "& th": {
                color: "rgba(96, 96, 96)",
                backgroundColor: "#D1D1D1",
                position: 'sticky',
                top: '0',
                marginBottom: '1rem',
                fontWeight: 'bold',
              }
            }}
            >
              <TableRow>
                {
                  tableColumns.map((item) => (
                    <TableCell align="left">{item}</TableCell>
                  ))
                }
                <TableCell align="left"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {viewdata &&
                viewdata.slice(pageNumber * rowsPerPage, (pageNumber + 1) * rowsPerPage).map((row, rowIndex) => (
                  <TableRow
                    key={rowIndex}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">{row.retailer_id} </TableCell>
                    <TableCell align="left" style={{ maxWidth: '9.5rem' }}>{row.retailer_name}</TableCell>
                    <TableCell align="left" style={{ maxWidth: '7.5rem' }}>{row.mobile_number}</TableCell>
                    <TableCell align="left" style={{ maxWidth: '9.5rem' }}>{row.email}</TableCell>

                    <TableCell align="left" style={{ maxWidth: '9.5rem' }}>{row.region_name}</TableCell>
                    <TableCell align="left" style={{ maxWidth: '9.5rem' }}>{row.state_name}</TableCell>

                  </TableRow>
                ))}
            </TableBody>
          </Table>

        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[100, 150, 200]}
          component="div"
          count={viewdata?.length}
          rowsPerPage={rowsPerPage}
          page={pageNumber}
          onPageChange={(event, newPage) => setPageNumber(newPage)}
          onRowsPerPageChange={(event) => { setRowsPerPage(parseInt(event.target.value, 10)); setPageNumber(0); }}
        />
      </Paper>

    </Card>
  );
}

export default ViewResultTable;
