import React from 'react';
import ViewResultCard from '../../Components/ViewResult/ViewResultCard';
import ViewResultTable from '../../Components/ViewResult/ViewResultTable';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, Box } from '@mui/material';
import { useNavigate, useLocation } from "react-router-dom";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';


const SummaryPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [isBackDialogOpen, setIsBackDialogOpen] = React.useState(false);

  console.log('state = ', state)
  const handleBackNavigation = () => {
    state.cohort_saved === true ? navigate('/') :
      navigate('/newcohort')

  };
  return (
    <div style={{ backgroundColor: "#F3F6F9" }}>

      <div style={{ padding: '0.5% 2%' }}>
        <ViewResultCard cohortDetails={state} />

        <ViewResultTable cohortDetails={state} />
      </div>

      <Dialog open={isBackDialogOpen} onClose={() => setIsBackDialogOpen(false)}>
        <DialogTitle>Back Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            All applied filters will be reseted.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsBackDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleBackNavigation} color="primary">
            Back
          </Button>

        </DialogActions>
      </Dialog>

    </div>
  );
}

export default SummaryPage;
