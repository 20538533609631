import React from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import './App.css';
import Navbar from './Components/Navbar/Navbar';
import LandingPage from './Pages/LandingPage/LandingPage';
import CohortPage from './Pages/CohortPage/Cohort';
import LoginPage from './Components/Login/LoginPage';
import SummaryPage from './Pages/SummaryPage/SummaryPage';
import Dashboard from './Pages/dashboard/Dashboard';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EditCohortPage from './Pages/EditCohortPage/EditCohortPage';
import AutomatedSegments from './Pages/AutomatedSegments/AutomatedSegments';

const App = () => {
  const location = useLocation();
  const isLoginPage = location.pathname === '/login';

  return (
    <div className="App" style={{ height: '100vh', overflowY: 'auto' }}>
      <ToastContainer />
      {!isLoginPage && (
        <div className="App-container" id="outer-container" style={{ position: "sticky", top: '0', zIndex: '4' }} >
          <Navbar></Navbar>
        </div>
      )}

      <div id="page-wrap" 
      // style={{ height: '100vh', overflowY: 'auto' }}
      >
        <Routes>

          <Route path="/login" element={<LoginPage />} />

          <Route
            path={'*'}
            element={<Navigate to={'/'} />}
          />

          <Route
            path={'/newcohort'}
            element={<CohortPage />}
          />
          <Route
            path={'/dashboard'}
            element={<Dashboard />}
          />

          {/* <Route
              path={'/' + REACT_APP_REGION + '/ui/doctor/:doctorId'}
              element={<DoctorLandingPage />}
            /> */}
          {/* <Route
            path={'/home'}
            element={<LandingPage uname='Nitin' />}
          /> */}
          <Route
            path={'/'}
            element={<LandingPage uname='Nitin' />}
          />
          <Route
            path={'/viewresult'}
            element={<SummaryPage />}
          />
          <Route
            path={'/editcohort'}
            element={<EditCohortPage />}
          />
          <Route
            path={'/automated-segments'}
            element={<AutomatedSegments />}
          />

        </Routes>
      </div>
    </div>
  );
}

export default App;