import React from 'react';
import ChartWrapper from '../ChartWrapper';

export default function DonutChart(props) {
    const { google, chartData, pieHole, sliceColors, chartArea, width = '100%', height = '100%', type = '', enableInteractivity = true, events = false, totalCount = '',
        company, distributor, source, timeRange, finalCustomDate, legend = 'none'
    } = props;

    // const colors = ['#fbc055', '#9DB8CF', '#E26463', '#5F60F0', '#fdcce5', '#8bd3c7', '#FC7C1A']
    const colors = ['#003DD3', '#EC4425', '#1FA3D9', '#F2BD66', '#A38DE3', '#A9DBFF'];
    return (
        <div id='donut-chart'>
            <ChartWrapper
                google={google}
                chartType="PieChart"
                options={{
                    title: '',
                    pieSliceBorderColor: 'transparent',
                    legend: {
                        alignment: 'center',
                        textStyle: {
                          fontSize: 14.4,
                        },
                      },
                    tooltip: { text: 'percentage' },
                    // tooltip: { isHtml: true, text: 'percentage' },
                    sliceVisibilityThreshold: 0,
                    pieHole: pieHole,
                    colors: colors,
                    // width: 550,
                    // height: 400,
                    width: width,
                    height: height,
                    pieSliceText: 'percentage',
                    // pieSliceText: 'label',
                    // slices: {
                    //     0: { offset: 0.03 },
                    // },
                    // is3D: true,
                    chartArea: {
                        height: '100%',
                        width:'100%',
                        top: 10,
                        left: 60,
                        bottom: 10,
                        right: 0,
                    },
                    enableInteractivity: enableInteractivity,
                }}
                // onSliceClick={type && handleSliceClick}     // if type present then only slice click works
                chartData={chartData}
            />
            {events && <div className='h3Medium' style={{ textAlign: 'center' }}>Total Events : <span style={{ fontWeight: '600' }}>{totalCount}</span></div>}
        </div>
    )
}