import {  Grid, Typography } from '@mui/material';
import React from 'react';
import {  TextField, Paper } from '@material-ui/core';
import LoginImage from "../../Assets/LoginImage.png";
import Bgimg from "../../Assets/Bgimg.png";
import { makeStyles } from '@material-ui/core/styles';
import pharmaracklogo from "../../Assets/pharmaracklogo.png";
import Checkbox from '@mui/material/Checkbox';
import { Button } from '@mui/base';
import { useNavigate  } from "react-router-dom";
import PersonPinIcon from '@mui/icons-material/PersonPin';



const useStyles = makeStyles((theme) => ({
    root: {
        backgroundImage: `url(${Bgimg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed', // Optional: This keeps the background fixed when scrolling
        minHeight: '100vh', // Ensure the background covers the entire viewport height
      },
      content: {
        // Add additional styles for your content if needed
        padding: '20px',
        backgroundColor: 'rgba(255, 255, 255, 0.8)', // Optional: Add a semi-transparent overlay to improve text readability
      },
      overlayImage: {
        position: 'absolute',
        marginLeft:"auto",
        width: '65%',
        height: '100%',
        opacity: 0.7, // Adjust the opacity as needed
      },
      paper: {
        padding: theme.spacing(2), // Adjust padding as needed
      },
      gridItem: {
        padding: theme.spacing(4), // Adjust padding as needed
         // Optional: Add a border for visualization
      },
      customTextField: {
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: 'white', // Change this color to your desired border color
          },
          '&:hover fieldset': {
            borderColor: 'white', // Change this color for hover effect
          },
        },
        '& .MuiInputBase-input': {
            color: 'white', // Change this color to your desired text color
          },
        '& .MuiInputLabel-root': {
            color: 'Grey', // Change this color to your desired label color
          },
         },
         customButton: {
            border: ' 1px solid white', // Increase border width and change color
            backgroundColor: '#4591D5', // Change the background (filled) color
            color: 'white', // Change the text color
            width: '230px', // Set the desired width
            height: '40px', // Set the desired height
            fontSize:'1rem'
          },
    }));
    const handleClick = () => {
        // Add your custom click event logic here
        alert('Button Clicked!');
      };
    

const LoginPage = () => {
    const classes = useStyles();
    const navigate = useNavigate();
  return (

    <div style={{ flexGrow: 1 }} className={classes.root}>
    <Grid container spacing={2}>
        
      <Grid item xs={4} className={classes.gridItem}>
        <div style={{textAlign:"center",marginTop:"10%"}}>
        <img src={pharmaracklogo}/>
        <div style={{ color:'rgb(200, 200, 200)',marginTop:"10%"}}>
        <h3>Welcome to Pharmarack,</h3>
        <h4 >Please sign in to continue</h4>
        </div>
        <div >
        <TextField
          id="outlined-basic-username"
          label="User Name"
          variant="outlined"
          style={{marginTop:"10%"}}
          className={classes.customTextField}
        />
        <TextField
          id="outlined-basic-password"
          label="Password"
          type = "password"
          variant="outlined"
          style={{marginTop:"5%"}}
          className={classes.customTextField}
        />
        <Typography style={{ color:'#286499',marginTop:"5%",width:"75%"}}>
        <Checkbox  style={{ color:'#286499'}}
        inputProps={{ 'aria-label': 'Custom Border Color Checkbox' }}/>
        Remember me
        </Typography>
        <Button className={classes.customButton}  variant="contained" onClick={() => { navigate('/') }}>Sign In</Button>
        <Typography style={{ color:'#286499',marginTop:"5%",width:"75%"}}>Forgot Password</Typography>
        <Typography style={{ color:'grey',marginTop:"5%",width:"60%"}}>Login as</Typography>
        </div>
        <div style={{border:"1px solid grey",width:"50px",height:"58px",marginLeft:"90px",textAlign:"center"}}>
        <Button variant="contained"  style={{ backgroundColor: '#2A5F90' }}>
        <PersonPinIcon sx={{width:"90%",height:"auto"}}/>
        <Typography style={{width:"70%", color:'white',marginTop:"5%",width:"5%",fontSize:"12px",textAlign:"center"}}>Vamsi</Typography>
        </Button>
        </div>
        </div>
        

      </Grid>
      <Grid item xs={8}>
        <Paper elevation={3}>
          <img src={LoginImage} className={classes.overlayImage}/>
        </Paper>
      </Grid>
    </Grid>
  </div>
  );
}

export default LoginPage;
