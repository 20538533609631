import React, { useState, useEffect, useRef } from 'react';

function ChartWrapper({
  google, chartType, chartData, options, onSliceClick,
}) {
  const [chart, setChart] = useState(null);
  const chartDataRef = useRef(chartData); 

  // Generating random alphanumeric chartIds
  const chartId = Math.random().toString(36).substring(2, 9);

  useEffect(()=>{
    chartDataRef.current = chartData;
  },[chartData])

  useEffect(() => {
    if (google && !chart) {
      const data = google.visualization.arrayToDataTable(chartData);

      const newChart = new google.visualization[chartType](document.getElementById(chartId));

      google.visualization.events.addListener(newChart, 'select', () => {
        // just verifying if it's a function
        if (typeof onSliceClick === 'function') {
          const selection = newChart.getSelection();
          if (selection.length === 1) {
            const row = selection[0].row;
            const selectedSlice = chartDataRef.current[row + 1][0];
            onSliceClick(selectedSlice);
          }
        }
      });

      newChart.draw(data, options);

      setChart(newChart);
    }
  }, [google, chart, onSliceClick]);

  useEffect(() => {
    if (google && chart) {
      const data = google.visualization.arrayToDataTable(chartData);
      chart.draw(data, options);
    }
  }, [chartData]);

  return (
    <div
      id={chartId}
    />
  );
}
export default ChartWrapper;