import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LineChartCopy from '../../../Components/charts/utils/LineChartCopy';
import useGoogleCharts from '../../../Components/charts/useGoogleChart';

const tableData = {
    tableHeader: ['Company Name', 'Brand Value', 'Brand Color', 'Trend'],
    tableBody: [
        {
            companyName: 'XYZ',
            brandValue: 2000,
            brandColor: 'Red',
            chartData: [
                ['Genre', 'Fantasy & Sci Fi', { role: 'style' }],
                ['Sep 22', 10, 'color: #3366CC'],
                ['Oct 22', 16, 'color: #3366CC'],
                ['Nov 22', 28, 'color: #3366CC'],
                ['Dec 22', 28, 'color: #3366CC'],
                ['Jan 23', 10, 'color: #3366CC'],
            ]
        },
        {
            companyName: 'ABC',
            brandValue: 2000,
            brandColor: 'Red',
            chartData: [
                ['Genre', 'Romance'],
                ['Sep 22', 3],
                ['Oct 22', 26],
                ['Nov 22', 18],
                ['Dec 22', 38],
                ['Jan 23', 9],
            ]
        },
        {
            companyName: 'DEF',
            brandValue: 2000,
            brandColor: 'Red',
            chartData: [
                ['Genre', 'General'],
                ['Sep 22', 22],
                ['Oct 22', 16],
                ['Nov 22', 28],
                ['Dec 22', 2],
                ['Jan 23', 10],
            ]
        }
    ]
}

const RND = () => {
    const google = useGoogleCharts();
    return (
        <div>
            <div style={{ margin: '1rem' }}>
                <TableContainer component={Paper} style={{ height: '43vh', overflowY: 'scroll' }}>
                    <Table stickyHeader size="small" aria-label="a audit list">
                        <TableHead sx={{
                            "& th": {
                                color: "rgba(96, 96, 96)",
                                backgroundColor: "#f1f1f1",
                                position: 'sticky',
                                top: '0',
                                marginBottom: '1rem',
                            }
                        }}
                        >
                            <TableRow>
                                {
                                    tableData.tableHeader.map((item, itemIndex) => (
                                        <TableCell align={'left'} >{item}</TableCell>
                                    ))
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableData && tableData.tableBody.map((row, rowIndex) => (
                                <TableRow
                                    key={rowIndex}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    {/* <TableCell component="th" scope="row">{rowIndex + 1}</TableCell> */}
                                    <TableCell component="th" scope="row">{row.companyName}</TableCell>
                                    <TableCell align="left">{row.brandValue}</TableCell>
                                    <TableCell align="left">{row.brandColor}</TableCell>
                                    <TableCell align="center">
                                        <LineChartCopy
                                            google={google}
                                            title=""
                                            vAxisTitle=""
                                            hAxisTitle=""
                                            chartData={row.chartData} />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}

export default RND
